import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap, tap, map, } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { postResponse } from 'src/raksme/models/contact-us/contact-us';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  private base_url = environment.cms_datas.base_url; 
  private _url: string = "api/";
  api_url: string;

  constructor(private http:HttpClient) { }

  insertFormData(param: string, bodyData: object): Observable<postResponse[]>{
    this.api_url = this.base_url + this._url + param;

    

    return this.http.post<postResponse[]>(this.api_url, bodyData)
                      .pipe(
                        map(
                          data => {
                            return data;
                          }
                        ),
                        catchError(this.errorHandler))
  }


  errorHandler(error: HttpErrorResponse){
    return throwError(error.message || "Server error");
  }

  

}
