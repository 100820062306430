import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NewsArticlesService } from 'src/raksme/services/news/news-articles.service';
import { Router } from '@angular/router';
import { GeneralLabelService } from 'src/raksme/services/general-label/label.service';

@Component({
  selector: 'home-news',
  templateUrl: './raksme.homeNewsView.html',
  styleUrls: ['./raksme.homeNewsStyle.css']
})
export class HomeNewsComponent implements OnInit, OnDestroy {
  title = 'rak-sme';
  public pageData: object;

  public subscriptions: Subscription[] = [];

  public newsData: any = [];

  public allItems: any[] = [];

  @Input("pageContent")
  set setPagesData(_pageData: object){
    this.pageData = _pageData;
  }

  public lang: string = 'en';
  public langUrl: string = '';
  public generalLabels: any = [];

  constructor(private newsService: NewsArticlesService, private router: Router, private labelService: GeneralLabelService){}

  ngOnInit(){

    

    if(this.router.url.includes('/ar') == true){
      this.lang = 'ar';
      this.langUrl = '/ar';
    }

    this.subscriptions.push(
      this.labelService.getLabels('general-label/'+this.lang).subscribe(
        res => {
          this.generalLabels = res['data'];
        },
        error => {
          console.log(error);
        }
      )
    );

    let bodyData: object = {
      "category" : 0
    }
    this.subscriptions.push(
      this.newsService.getNewsOnHome('news/'+this.lang, bodyData).subscribe(
        res => {
          this.newsData = res;

          let i = 1;
          for(var x in this.newsData.data){
            this.allItems.push(this.newsData.data[x]);
            if(i == 3){
              break;
            }
            i++
          }
          
        },
        error => console.log(error)
      )
    );
  }

  ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
