<div class="secondarybar-wrap bb-fade-white-015 container-fluid mobile-header-visible-module rak-secondary-header-bar {{langClass}}">
    <div class="secondarybar-container">
        <div class="secondarybar">
            <div class="row secondarybar-row align-items-center  px-4">
                <div class="col-sm-5 mobile-header-hide-module">
                    <div class="header-module mobile-header-hide-module">
                        <div class="iconbox iconbox-inline iconbox-custom">
                            <div class="iconbox-icon-wrap">
                                <span class="iconbox-icon-container font-size-15 text-white">
                                <i class="fa fa-phone" aria-hidden="true"></i>
                                </span>
                            </div>
                            <h3 class="font-size-14 text-white ar-font-size-17" *ngIf="header_call != undefined && SettingsData?.website_phone != undefined"><span>{{header_call}}: </span> <span class="header_rak_font" style="direction: ltr;unicode-bidi: embed;" [innerHTML]="SettingsData?.website_phone"></span></h3>
                            <h3 class="font-size-14 text-white ar-font-size-17" *ngIf="header_call == undefined && SettingsData?.website_phone == undefined"><ngx-skeleton-loader [theme]="{  width: '100px', height: '15px', 'background-color': '#808291', 'margin-bottom': '0' }" ></ngx-skeleton-loader></h3>
                        </div>
                    </div>
                    <div class="header-module mobile-header-hide-module">
                        <div class="iconbox iconbox-inline iconbox-custom">
                            <div class="iconbox-icon-wrap">
                                <span class="iconbox-icon-container font-size-15 text-fade-white-08">
                                <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                </span>
                            </div>
                            <h3 class="font-size-14 text-white ar-font-size-17 header_rak_font" *ngIf="SettingsData?.website_email != undefined">{{SettingsData?.website_email}}</h3>
                            <h3 class="font-size-14 text-white ar-font-size-17" *ngIf="SettingsData?.website_email == undefined"><ngx-skeleton-loader [theme]="{  width: '100px', height: '15px', 'background-color': '#808291', 'margin-bottom': '0', 'margin-top': '5px' }" ></ngx-skeleton-loader></h3>
                        </div>
                    </div>
                </div>
                <div class="col-sm-7 col-xs-12 text-right p-0" [class.row]="isMobile == true" id="topHeader_mob">

                    

                    <!-- Search -->
                    <div class="header-module search-header-module">
                        <div class="ld-module-search">
                            <div class="rak-ld-search-form-container">
                                <form role="search" [formGroup]="searchForm" novalidate="true" (ngSubmit)="submittingSearch()" class="ld-search-form">
                                    <input type="text" placeholder="{{searchPlaceholder}}" value="" name="search" formControlName="search">
                                    <span (click)="submittingSearch()" role="search" class="input-icon collapsed" data-ld-toggle="true" data-toggle="collapse" data-target="#header-search" aria-controls="header-search" aria-expanded="false">
                                        <i class="icon-ld-search"></i>
                                    </span>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- End -->

                    

                    
                    

                    <div class="header-module mobile-header-visible-module" [class.col-sm-3]="isMobile == true" [class.pr-0]="isMobile == true && lang == 'en'" [class.pl-0]="isMobile == true && lang == 'ar'">
                        <raksme-social-icons [socialLoaded]="socialLoaded" [socialsData]="headerSocials.data"></raksme-social-icons>
                    </div>
                    


                    <div class="header-module mobile-header-visible-module" [class.col-sm-3]="isMobile == true" [class.pr-0]="isMobile == true && lang == 'en'" [class.pl-0]="isMobile == true && lang == 'ar'" *ngIf="lang != 'ar';else arSwitch">
                        <div class="iconbox iconbox-inline iconbox-custom">
                            <div class="iconbox-icon-wrap mobile-header-hide-module">
                                <span class="iconbox-icon-container font-size-15 text-fade-white-08 header-menu-color-module">
                                <i class="fa fa-globe" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div class="ld-dropdown-menu">
                                <span class="ld-module-trigger  text-white collapsed" role="button" data-ld-toggle="true" data-toggle="collapse" data-target="#dropdown-language" aria-controls="dropdown-language" aria-expanded="false">
                                <span class="ld-module-trigger-txt lng_btn" *ngIf="generalLabels?.language_btn_english != undefined">{{generalLabels?.language_btn_english}} <i class="fa fa-angle-down"></i></span>
                                <span class="ld-module-trigger-txt" *ngIf="generalLabels?.language_btn_english == undefined"><ngx-skeleton-loader [theme]="{  width: '60px', height: '15px', 'background-color': '#808291', 'margin-bottom': '0', 'margin-top': '5px' }" ></ngx-skeleton-loader> <i class="fa fa-angle-down"></i></span>
                                </span>
                                <div class="ld-module-dropdown left collapse" id="dropdown-language" aria-expanded="false" role="tabpanel" style="height: 0px;margin-top: 10px;">
                                    <div class="ld-dropdown-menu-content">
                                        <ul>
                                            <li><a routerLink="ar">{{generalLabels?.language_btn_arabic}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <ng-template #arSwitch>
                        <div class="header-module mobile-header-visible-module" [class.col-sm-3]="isMobile == true" [class.pr-0]="isMobile == true && lang == 'en'" [class.pl-0]="isMobile == true && lang == 'ar'">
                            <div class="iconbox iconbox-inline iconbox-custom">
                                <div class="iconbox-icon-wrap mobile-header-hide-module">
                                    <span class="iconbox-icon-container font-size-15 text-fade-white-08 header-menu-color-module">
                                    <i class="fa fa-globe" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div class="ld-dropdown-menu">
                                    <span class="ld-module-trigger  text-white collapsed" role="button" data-ld-toggle="true" data-toggle="collapse" data-target="#dropdown-language" aria-controls="dropdown-language" aria-expanded="false">
                                    <span class="ld-module-trigger-txt lng_btn" *ngIf="generalLabels?.language_btn_arabic != undefined">{{generalLabels?.language_btn_arabic}} <i class="fa fa-angle-down"></i></span>
                                    <span class="ld-module-trigger-txt" *ngIf="generalLabels?.language_btn_arabic == undefined"><ngx-skeleton-loader [theme]="{  width: '60px', height: '15px', 'background-color': '#808291', 'margin-bottom': '0', 'margin-top': '5px' }" ></ngx-skeleton-loader> <i class="fa fa-angle-down"></i></span>
                                    </span>
                                    <div class="ld-module-dropdown left collapse" id="dropdown-language" aria-expanded="false" role="tabpanel" style="height: 0px;margin-top: 10px;">
                                        <div class="ld-dropdown-menu-content">
                                            <ul>
                                                <li><a routerLink="../">{{generalLabels?.language_btn_english}}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </ng-template>

                    <div class="header-module mobile-header-visible-module mobile-menu-dark-color" [class.col-sm-3]="isMobile == true" [class.pr-0]="isMobile == true && lang == 'en'" [class.pl-0]="isMobile == true && lang == 'ar'" [class.mt5-5]="isMobile == true" *ngIf="toke_exist == true; else loginBtn">

                        

                        <div class="ld-dropdown-menu text-white btn-account">
                            <span class="ld-module-trigger  text-white collapsed" role="button" data-ld-toggle="true" data-toggle="collapse" data-target="#dropdown-account" aria-controls="dropdown-account" aria-expanded="false">
                            <span class="ld-module-trigger-txt" *ngIf="header_account != undefined">{{header_account}} <i class="fa fa-angle-down"></i></span>
                            <span class="btn-txt" *ngIf="header_account == undefined"><ngx-skeleton-loader [theme]="{  width: '60px', height: '15px', 'background-color': '#808291', 'margin-bottom': '0' }" ></ngx-skeleton-loader></span>
                            </span>
                            <div class="ld-module-dropdown left collapse" id="dropdown-account" aria-expanded="false" role="tabpanel" style="height: 0px;margin-top: 10px;">
                                <div class="ld-dropdown-menu-content">
                                    <ul>
                                        <li><a routerLink = "/my-account/{{arUrl}}/dashboard">{{generalLabels?.header_dashboard}}</a></li>
                                        <li><a routerLink = "/my-account/{{arUrl}}/services">{{generalLabels?.header_account_service}}</a></li>
                                        <li><a routerLink = "/my-account/{{arUrl}}/applications">{{generalLabels?.header_account_applications}}</a></li>
                                        <li><a routerLink = "/my-account/{{arUrl}}/settings">{{generalLabels?.header_account_settings}}</a></li>
                                        <li><a href="javascript:void(0)" (click)="openLogout(template)">{{generalLabels?.header_account_logout}}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div> 

                        
                    </div>

                    <div class="header-module mobile-header-visible-module desktop-header-hidden-module mobile-search-container" [class.col-sm-3]="isMobile == true" [class.pr-0]="isMobile == true && lang == 'en'" [class.pl-0]="isMobile == true && lang == 'ar'" [class.mt5-5]="isMobile == true">
                        <ul class="social-icon social-icon-md search-button">
                            <li>
                                <a class="search-open text-white" href="javascript:void(0)"><i class="fa fa-search"></i></a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-inline" style="height: 54px;">
    <form class="form ld-serach-mobile-form" [formGroup]="searchForm" novalidate="true" (ngSubmit)="submittingSearch()">
       <input type="text" class="form-control SearchBox"  placeholder="{{searchPlaceholder}}" name="search" formControlName="search">
       <button class="SearchSubmit">
       <i class="fa fa-search"></i>
       </button>
       <a href="javascript:void(0)" class="search-close">
       <i class="fa fa-times"></i>
       </a>
    </form>
 </div>

<ng-template #loginBtn>
    <div class="header-module mobile-header-visible-module mobile-menu-dark-color" [class.col-sm-3]="isMobile == true" [class.pr-0]="isMobile == true && lang == 'en'" [class.pl-0]="isMobile == true && lang == 'ar'">
        <a routerLink = "/sign-in{{arUrl}}" class="text-white btn-account btn-reglog">
            <span class="iconbox-icon-container font-size-15 text-fade-white-08">
                <i class="fa fa-user-o mr-2" aria-hidden="true"></i>
            </span>
            <span>
                <span class="btn-txt">{{header_login}}</span>
            </span>
        </a>
    </div>
</ng-template>


<ng-template #template>
    <div class="modal-body text-center">
      <p>{{generalLabels?.logout_popup_msg}} </p>
      <button type="button" class="logout_pop_btn btn btn-solid semi-round border-thin font-mobile-12px" (click)="confirm()" >
          <span class="btn-txt">{{generalLabels?.logout_popup_ok_btn}}</span>
      </button>
      <button type="button" class="logout_pop_btn btn btn-default semi-round btn-bordered border-thin border-fade-white-03 font-mobile-12px {{langClass}} btn-no" (click)="decline()" >
          <span class="btn-txt">{{generalLabels?.logout_popup_cancel_btn}}</span>
      </button>
    </div>
  </ng-template>