import { Component, OnInit, Input, OnDestroy, ElementRef, TemplateRef } from '@angular/core';
import { AuthService } from 'src/raksme/services/auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { WidgetsService } from 'src/raksme/services/widgets/widgets.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'raksme-secbar',
    templateUrl: './raksme.secBarView.html',
    styleUrls: ['./raksme.secBarStyle.css'],
    host: {
      "(window:resize)":"WindowResize($event)"
    }
})
export class SecBarComponent implements OnInit,OnDestroy {
  title = 'rak-sme';

  public subscriptions: Subscription[] = [];

  public userLoginDetails: any = [];

  public toke_exist: boolean = false;

  public lang;
  public arUrl: any = '';
  public headerSocials: any = [];
  public generalLabels: any;
  public header_call: string;
  public header_account: string;
  public header_login: string;

  public SettingsData: any;

  public socialLoaded: boolean = false;
  public loadGeneral: boolean = false;
  public loadSiteSettings: boolean = false;

  public isMobile: boolean = false;

  public screenWidth: number = window.innerWidth;
  public searchPlaceholder: string = '';

  @Input('generalLabel')
  set setGeneralLabel(_labelData: any){
    this.generalLabels = _labelData;
    this.header_call = _labelData?.header_call;
    this.header_account = _labelData?.header_account;
    this.header_login = _labelData?.header_login_register;
    this.searchPlaceholder = _labelData?.header_search_placeholder;
    this.loadGeneral = true;
  }

  @Input('siteSettings')
  set setSiteSettings(_siteSettings: any){
    this.SettingsData = _siteSettings;
    this.loadSiteSettings = true;
  }

  searchForm: FormGroup;

  public searchVal: string = '';

  public langClass: string = "english-class-raksme";

  resizeObservable$: Observable<Event>
resizeSubscription$: Subscription;

modalRef: BsModalRef;



  constructor(private auth: AuthService, private router: Router, private headerService: WidgetsService, private el: ElementRef, private fb: FormBuilder, private activateRoute: ActivatedRoute, private spinner: NgxSpinnerService, private modalService: BsModalService){}

  ngOnInit(){

    

    
    
    if (this.router.url.includes('/ar') == true){
      this.lang = 'ar';
      this.arUrl = '/ar';
      this.langClass = "arabic-class-raksme";
    }else{
      this.lang = 'en';
    }

    const storage_token = localStorage.getItem('RAKSME_TOKEN');
    if(storage_token) { 
      let item = JSON.parse(storage_token);
      const now = new Date();
      if(now.getTime() > item.expiry){
        
        localStorage.removeItem('RAKSME_TOKEN');
        this.toke_exist = false;
      }else{
        this.toke_exist = true;
        const data = {
          value: item.value,
          expiry: now.getTime() + 1800000
        }
        localStorage.setItem('RAKSME_TOKEN', JSON.stringify(data));
      }
      
    }

    //getsocilas
    this.subscriptions.push(
      this.headerService.getFooterSocials('socials/header').subscribe(
        res => {
          this.headerSocials = res;
          this.socialLoaded = true;
        },
        error => console.log(error)
      )
    );

    //setting column for header
    
    if(this.screenWidth <= 1199){
      this.isMobile = true;
      let myTag = this.el.nativeElement.querySelector("#topHeader_mob");
      myTag.classList.remove('text-right');
      myTag.classList.remove('col-sm-7');
    }

    if(this.screenWidth >= 1200){
      this.isMobile = false;
      let myTag = this.el.nativeElement.querySelector("#topHeader_mob");
      myTag.classList.add('col-sm-7');
    }

    if(this.screenWidth < 480){
      let myTag = this.el.nativeElement.querySelector("#topHeader_mob");
      myTag.classList.remove('col-sm-7');
    }
    

    //declairing search form

    this.searchForm = this.fb.group({
      search: [this.searchVal, [Validators.required]]
    });

  }


  openLogout(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-md'});
  }

  confirm(): void {
    this.logout();
    this.modalRef.hide();
  }
 
  decline(): void {
    this.modalRef.hide();
  }


  logout(){
    this.spinner.show();
    this.auth.logout();
    this.spinner.hide();
  }


  submittingSearch(){
    
    if(this.searchForm.valid){
      const searchTerm = this.searchForm.value['search'];

      
      this.router.navigate(['/search/'+this.arUrl], { queryParams: { s: searchTerm } });
    }
  }

  WindowResize(event){
    this.screenWidth = event.target.innerWidth;
    //setting column for header
    if(this.screenWidth <= 1199){
      this.isMobile = true;
      let myTag = this.el.nativeElement.querySelector("#topHeader_mob");
      myTag.classList.remove('text-right');
      myTag.classList.remove('col-sm-7');
    }

    if(this.screenWidth >= 1200){
      this.isMobile = false;
      let myTag = this.el.nativeElement.querySelector("#topHeader_mob");
      myTag.classList.add('col-sm-7');
    }

    if(this.screenWidth < 480){
      let myTag = this.el.nativeElement.querySelector("#topHeader_mob");
      myTag.classList.remove('col');
    }
  }

  ngOnDestroy(){
    this.subscriptions.forEach((sub) => sub.unsubscribe());
     
  }
  
}
