import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { instagramFeed } from 'src/raksme/models/instagram-feed/instagram-feed';

@Injectable({
  providedIn: 'root'
})
export class InstagramFeedService {

  
  api_url: string;

  constructor(public http: HttpClient) { }

  getInstaFeed(param: object): Observable<instagramFeed[]>{
    let instaUrl = 'https://graph.instagram.com/me/media?';
    let fields = param['fields'];
    let access_token = param['access_token'];
    let limit = param['limit'];

    this.api_url = instaUrl + 'fields=' + fields + '&access_token=' + access_token + '&limit=' + limit;
    return this.http.get<instagramFeed[]>(this.api_url).pipe(
      switchMap(data => {
        return of(data);
      }),
      catchError(this.errorHandler))
    
  }

  errorHandler(error: HttpErrorResponse){
    return throwError(error.message || "Server error");
  }

}
