<section class="vc_row py-5" data-section-luminance="light" *ngIf="pageData['home_about_active'] == 1">
    <div class="container">
        <div class="row d-flex flex-wrap align-items-center">
            <div class="lqd-column col-md-6">
                <h2 class="mt-2 mb-3 font-weight-normal lh-1"><strong [innerHTML]="pageData['home_about_title'] | safe: 'html'">Where ideas <br>are transformed</strong></h2>
                <p class="h3 font-weight-normal pr-md-7 mr-md-7 mb-40"><i [innerHTML]="pageData['home_about_subtitle'] | safe: 'html'">in to business projects.</i></p>
                <p class="font-size-18 lh-15 mb-5 pr-md-7 mr-md-7 mb-60" [innerHTML]="pageData['home_about_desc'] | safe: 'html'">RAK SME embraces all national enterpreneurs, providing them with all the support and guidance needed in order to launch their own projects through a number of integrated services, exemptions and facilities. </p>
                <a routerLink="/{{pageData['home_about_btn_url']}}" [target]="pageData['home_about_btn_new_window'] ? '_blank' : '_self'" class="btn btn-solid text-uppercase semi-round border-thin font-weight-bold home_about_button">
                    <span class="btn-txt" [innerHTML]="pageData['home_about_btn_label'] | safe: 'html'">Learn More</span>
                </a>
            </div>
            <div class="lqd-column col-md-5 col-md-offset-1">
                <div class="lqd-parallax-images">
                    <div class="liquid-img-group-single">
                        <div class="liquid-img-group-img-container">
                            <div class="liquid-img-container-inner">
                                <figure><img [src]="pageData['home_about_back_img']" /></figure>
                            </div>
                        </div>
                    </div>
                    <div class="liquid-img-group-single w-75">
                        <div class="liquid-img-group-img-container">
                            <div class="liquid-img-container-inner">
                                <figure><img [src]="pageData['home_about_front_img']" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12" style="position: absolute;z-index: -1;bottom: 0;">
            <!-- <img src="../../../../../assets/img/pattern-10.png" style="max-width: 400px;opacity: 0.05;float: left;"> -->
        </div>
    </div>
</section>