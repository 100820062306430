import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import TypeIt from "typeit";
import { Subscription } from 'rxjs';
import { PagesService } from 'src/raksme/services/pages/pages.service';
import { Router } from '@angular/router';
@Component({
  selector: 'home-banner',
  templateUrl: './raksme.homeBannerView.html',
  styleUrls: ['./raksme.homeBannerStyle.css'],
  host: {
    "(window:resize)":"WindowResize($event)"
  }
})
export class HomeBannerComponent implements OnInit, OnDestroy {
  title = 'Home Banner';
  public pageData: object;
  public url: any = "http://staging.naasdigital.com/rak-sme/layouts/assets/videos/RAK-3.mp4";
  public typingData: any;
  public typingA: any[] =[];
  public bgImage = {};

  public isMobile: boolean = false;

  public screenWidth: number = window.innerWidth;

  private subscriptions: Subscription[] = [];
  public lang = 'en';


  @Input("pageContent")
  set setPagesData(_pageData: object){
    this.pageData = _pageData;
    this.typingData = this.pageData["home_banner_title_typing1"]+","+this.pageData["home_banner_title_typing2"];
    this.typingA = this.typingData.split(',');

    

    

    if(this.screenWidth < 1119){
      this.bgImage = {
        backgroundImage: 'url('+_pageData['home_banner_bg']+')'
      };

      this.isMobile = true;
    }
  }

  
  constructor(private pageService: PagesService, private router: Router){
    
  }

  ngOnInit(){
    let pageData = {
      "page_url": "home"
    };
    if(this.router.url.includes('/ar') == true){
      this.lang = 'ar';
      this.subscriptions.push(
        this.pageService.getPages('pages/ar', pageData).subscribe(
          res => {
            let dataRes: any = res;
            let content = dataRes.data[0].content;
            this.loadTypewriting(content.home_banner_title_typing1_ar, content.home_banner_title_typing2_ar);
          }
        )
      );
    }else{
      this.subscriptions.push(
        this.pageService.getPages('pages/en', pageData).subscribe(
          res => {
            let dataRes: any = res;
            let content = dataRes.data[0].content;
            this.loadTypewriting(content.home_banner_title_typing1, content.home_banner_title_typing2);
          }
        )
      );
      
    }
   
  }

  WindowResize(event){
    this.screenWidth = event.target.innerWidth;
    if(this.screenWidth < 1119){
      this.bgImage = {
        backgroundImage: 'url('+this.pageData['home_banner_bg']+')'
      };

      this.isMobile = true;
    }
  }

  loadTypewriting(type1, type2){
    const type1len = "-"+type1.length;
    const type2len = type2.length;
    
    new TypeIt("#raktyping", {
      speed: 180,
      deleteSpeed: 80,
      loop: true
    })
      .type(type1)
      .pause(500)
      .delete(type1len)
      .pause(500)
      .type(type2)
      .go();

      
  }

  ngOnDestroy(){
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
  

  }

    
  
