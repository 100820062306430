import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap, take, } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

//models
import { NewsCategories, News } from 'src/raksme/models/media-centres/news-events';

@Injectable({
  providedIn: 'root'
})
export class NewsArticlesService {

  private base_url = environment.cms_datas.base_url; 
  private _url: string = "api/";
  api_url: string;

  constructor(public http:HttpClient) { }

  getCategories(param: string): Observable<NewsCategories[]>{
    this.api_url = this.base_url + this._url + param;
    return this.http.get<NewsCategories[]>(this.api_url)
                  .pipe(
                    switchMap(data => {
                      return of(data);
                    }),
                    catchError(this.errorHandler))
  }


  getAllNews(param: string, bodyData:object): Observable<News[]>{
    this.api_url = this.base_url + this._url + param;
    return this.http.post<News[]>(this.api_url, bodyData)
                  .pipe(
                    switchMap(data => {
                      return of(data);
                    }),
                    catchError(this.errorHandler))
  }


  //latest news for home page
  getNewsOnHome(param: string, bodyData:object): Observable<News[]>{
    this.api_url = this.base_url + this._url + param;
    return this.http.post<News[]>(this.api_url, bodyData)
                  .pipe(
                    switchMap(data => {
                      return of(data);
                    }),
                    catchError(this.errorHandler))
  }

  errorHandler(error: HttpErrorResponse){
    return throwError(error.message || "Server error");
  }
}
