<div class="bg-fade-dark-01 pt-35 pb-35 mt-70 {{langClass}}">
    <div class="container">
        <div class="row">
            <div class="lqd-column col-md-6">
                <ul class="lqd-custom-menu reset-ul inline-nav" *ngIf="coptRightMenus.data?.length >0 && coptRightMenus.status == 1 else copyMenu">
                    <li *ngFor="let menu of coptRightMenus.data">
                        <a *ngIf="menu.pages_id != 0 && menu.new_windows == 0" routerLink="/{{menu.link}}">{{menu.name}}</a>
                        <a *ngIf="menu.pages_id == 0 && menu.new_windows == 0" href="{{menu.link}}">{{menu.name}}</a>

                        <a *ngIf="menu.pages_id != 0 && menu.new_windows == 1" routerLink="/{{menu.link}}">{{menu.name}}</a>
                        <a *ngIf="menu.pages_id == 0 && menu.new_windows == 1" href="{{menu.link}}">{{menu.name}}</a>

                    </li>
                </ul>
            </div>
            <div class="lqd-column col-md-6 text-md-right" *ngIf="copyrighFooter.data?.length > 0 && copyrighFooter.status==1 else copyTxt">
                <p class="my-0 font-size-15" [innerHTML]="copyrighFooter_text | safe: 'html'"></p>
            </div>
        </div>
    </div>
</div>

<ng-template #copyTxt>
    <div class="lqd-column col-md-6 text-md-right">
        <ngx-skeleton-loader
        count="1"
        [animation]="animation"
        [theme]="{
          'border-radius': '1px',
          'height': '10px',
          'background-color': '#808291',
          border: '1px solid #808291',
          'animation-duration': '2s'
        }"
      >
      </ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #copyMenu>
    <ul class="lqd-custom-menu reset-ul inline-nav">
        <ngx-skeleton-loader
        count="4"
        appearance="circle"
        [animation]="animation"
        [theme]="{
          'border-radius': '1px',
          'height': '10px',
          'background-color': '#808291',
          border: '1px solid #808291',
          'animation-duration': '2s'
        }"
      >
      </ngx-skeleton-loader>
    </ul>
</ng-template>