<ul class="social-icon social-icon-md" *ngIf="socialLoaded == true">
    <li *ngFor="let social of headerSocialsData">
        <a class="text-white" href="{{social.url}}" title="{{social.name}}" target="_blank"><i class="fa fa-{{social.name}}"></i></a>
    </li>
</ul>

<ul class="social-icon social-icon-md" *ngIf="socialLoaded == false">
    <li *ngFor="let social of [1, 2, 3]">
        <a class="text-white" target="_blank"><i class="fa"><ngx-skeleton-loader [theme]="{  width: '20px', height: '20px', 'background-color': '#808291', 'margin-bottom': '0', 'margin-top': '5px' }" ></ngx-skeleton-loader></i></a>
    </li>
</ul>