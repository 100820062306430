import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PagesService } from 'src/raksme/services/pages/pages.service';
import { ScriptService } from 'src/raksme/services/scripts/script.service';
import { Router } from '@angular/router';
import { SeoService } from 'src/raksme/services/seo/seo.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { InstagramFeedService } from 'src/raksme/services/instagram-feed/instagram-feed.service';

@Component({
  templateUrl: './raksme.homeView.html',
  styleUrls: ['./raksme.homeStyle.css']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

  

  title = 'rak-sme';
  private subscriptions: Subscription[] = [];
  public pageDatas:any = [];

  public homeBanner: object = {
    home_banner_active: "",
    home_banner_title: "",
    home_banner_title_typing1: "",
    home_banner_title_typing2: "",
    home_banner_desc: "",
    home_banner_mp4: "",
    home_banner_webm: "",
    home_banner_ogv: "",
    home_banner_button1_label: "",
    home_banner_button1_url: "",
    home_banner_button2_label: "",
    home_banner_button2_url: "",
    home_banner_btn1_new_window: "",
    home_banner_btn2_new_window: "",
    home_banner_bg: "",
    home_banner_tooltip: "",
  }

  public homeAbout: object = {
    home_about_active: "",
    home_about_title: "",
    home_about_subtitle: "",
    home_about_desc: "",
    home_about_btn_label: "",
    home_about_btn_url: "",
    home_about_front_img: "",
    home_about_back_img: "",
    home_about_btn_new_window: "",
    home_about_tooltip: "",
  }

  public homeService: object = {
    home_services_active: "",
    home_services_title: "",
    home_services_subtitle: "",
    service_more_btn_label: "",
    service_more_btn_pageUrl: "",
    service_more_btn_new_window: "",
    home_services_tooltip: ""
  }

  public homeEvents: object = {
    home_events_active: "",
    home_events_tooltip: "",
    home_events_title: "",
    home_events_subtitle: "",
    home_events_more_btn_label: "",
    home_events_more_btn_pageUrl: "",
    home_events_id: "",
    home_events_more_btn_new_window: "",
  }

  public homeStories: object = {
    home_stories_active: "",
    home_stories_top_img: "",
    home_stories_middle_img: "",
    home_stories_bottom_img: "",
    home_stories_title: "",
    home_stories_subtitle: "",
    home_stories_desc: "",
    home_stories_more_btn_label: "",
    home_stories_more_btn_pageUrl: "",
    home_stories_more_btn_new_window: "",
    home_stories_tooltip: "",
  }

  public homeArticles: object = {
    home_articles_active: "",
    home_articles_title: "",
    home_articles_subtitle: "",
    home_articles_more_btn_label: "",
    home_articles_more_btn_pageUrl: "",
    home_articles_more_btn_new_window: "",
    home_articles_tooltip: ""
  }

  public homeMap: object = {
    hopme_map_active: "",
    hopme_map_link: "",
    hopme_map_tooltip: "",
    instagramFeed: "",
    home_instgram_active: "",
    home_insta_feed_count: ""

  }

 public lang: any = '';
 public langUrl: string = '';

 public instagramLoaded: boolean = false;

 public access_token: any = '';
  public limit: any = 10;
  public instaDatas: any = [];

  constructor(private pageService: PagesService, private scriptService: ScriptService, private router: Router, private seo: SeoService, private instaService: InstagramFeedService){}

  ngOnInit(){

    this.removingBodyHtmlClass();

    if(this.router.url.includes('/ar') == true){
      this.lang = 'ar';
      this.hidePPNav();
      this.langUrl = '/ar';
    }else{
      this.lang = 'en';
      this.hidePPNav();
    }

    let pageData = {
      "page_url": "home"
    };

    if(this.lang == 'ar'){
      this.subscriptions.push(

        this.pageService.getPages('pages/ar', pageData).subscribe(
          res => {
            this.pageDatas = res;
  
            let content = this.pageDatas.data[0].content;

            //declariring seo
            
  
            //home banner
            this.homeBanner = {
              home_banner_active: content.home_banner_active ? 1 : 0,
              home_banner_title: content.home_banner_title_ar,
              home_banner_title_typing1: content.home_banner_title_typing1_ar,
              home_banner_title_typing2: content.home_banner_title_typing2_ar,
              home_banner_desc: content.home_banner_desc_ar,
              home_banner_mp4: content.home_banner_mp4,
              home_banner_webm: content.home_banner_webm,
              home_banner_ogv: content.home_banner_ogv,
              home_banner_button1_label: content.home_banner_button1_label_ar,
              home_banner_button1_url: content.home_banner_button1_url_ar,
              home_banner_button2_label: content.home_banner_button2_label_ar,
              home_banner_button2_url: content.home_banner_button2_url_ar,
              home_banner_btn1_new_window: content.home_banner_btn1_new_window ? 1 : 0,
              home_banner_btn2_new_window: content.home_banner_btn2_new_window ? 1 : 0,
              home_banner_bg: this.pageDatas.data[0].header_img,
              home_banner_tooltip: content.home_banner_tooltip_ar, 
              home_banner_id: content.home_banner_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
            }
  
            //home about
            this.homeAbout = {
              home_about_active: content.home_about_active ? 1 : 0,
              home_about_title: content.home_about_title_ar,
              home_about_subtitle: content.home_about_subtitle_ar,
              home_about_desc: content.home_about_desc_ar,
              home_about_btn_label: content.home_about_btn_label_ar,
              home_about_btn_url: content.home_about_btn_url,
              home_about_front_img: this.pageDatas.data[0].body_img_path + content.home_about_front_img,
              home_about_back_img: this.pageDatas.data[0].body_img_path + content.home_about_back_img,
              home_about_btn_new_window: content.home_about_btn_new_window ? 1 : 0,
              home_about_tooltip: content.home_about_tooltip_ar,
              home_about_id: content.home_about_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
            }
  
            //home services
            this.homeService = {
              home_services_active: content.home_services_active ? 1 : 0,
              home_services_title: content.home_services_title_ar,
              home_services_subtitle: content.home_services_subtitle_ar,
              service_more_btn_label: content.service_more_btn_label_ar,
              service_more_btn_pageUrl: content.service_more_btn_pageUrl,
              service_more_btn_new_window: content.service_more_btn_new_window,
              home_services_tooltip: content.home_services_tooltip_ar,
              home_services_id: content.home_services_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
            }

            //home events
             this.homeEvents = {
              home_events_active: content.home_stories_active ? 1 : 0,
              home_events_tooltip: content.home_events_tooltip_ar,
              home_events_title: content.home_events_title_ar,
              home_events_subtitle: content.home_events_subtitle_ar,
              home_events_more_btn_label: content.home_events_more_btn_label_ar,
              home_events_more_btn_pageUrl: content.home_events_more_btn_pageUrl,
              home_events_id: content.home_events_tooltip,
              home_events_more_btn_new_window: content.home_events_more_btn_new_window,
            }
  
            //home stories
            this.homeStories = {
              home_stories_active: content.home_stories_active ? 1 : 0,
              home_stories_top_img: this.pageDatas.data[0].body_img_path + content.home_stories_top_img,
              home_stories_middle_img: this.pageDatas.data[0].body_img_path + content.home_stories_middle_img,
              home_stories_bottom_img: this.pageDatas.data[0].body_img_path + content.home_stories_bottom_img,
              home_stories_title: content.home_stories_title_ar,
              home_stories_subtitle: content.home_stories_subtitle_ar,
              home_stories_desc: content.home_stories_desc_ar,
              home_stories_more_btn_label: content.home_stories_more_btn_label_ar,
              home_stories_more_btn_pageUrl: content.home_stories_more_btn_pageUrl,
              home_stories_more_btn_new_window: content.home_stories_more_btn_new_window,
              home_stories_tooltip: content.home_stories_tooltip_ar,
              home_stories_id: content.home_stories_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
            }
  
            //home articles
            this.homeArticles = {
              home_articles_active: content.home_articles_active ? 1 : 0,
              home_articles_title: content.home_articles_title_ar,
              home_articles_subtitle: content.home_articles_subtitle_ar,
              home_articles_more_btn_label: content.home_articles_more_btn_label_ar,
              home_articles_more_btn_pageUrl: content.home_articles_more_btn_pageUrl,
              home_articles_more_btn_new_window: content.home_articles_more_btn_new_window,
              home_articles_tooltip: content.home_articles_tooltip_ar,
              home_articles_id: content.home_articles_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
            }
  
            this.homeMap = {
              hopme_map_active: content.hopme_map_active ? 1 : 0,
              hopme_map_link: content.hopme_map_link,
              hopme_map_tooltip: content.hopme_map_tooltip_ar,
              instagramFeed: content.home_instgram_feed,
              hopme_map_id: content.hopme_map_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,''),
              home_instgram_active: content.home_instgram_active,
              home_insta_feed_count: content.home_insta_feed_count,

            }

            this.access_token = this.homeMap['instagramFeed'];
            this.limit = this.homeMap['home_insta_feed_count'];

            this.getInstaFeed(this.access_token, this.limit);
            
            this.seo.generateTags({
              title: this.pageDatas.data[0].title, 
              description: this.pageDatas.data[0].desc, 
              image: this.pageDatas.data[0].header_img,
              slug: this.pageDatas.data[0].url
            });
            
            
            
            
          },
          error => console.log(error)
        )
      );

      

    }else{
      this.subscriptions.push(

        this.pageService.getPages('pages/en', pageData).subscribe(
          res => {
            this.pageDatas = res;
  
            let content = this.pageDatas.data[0].content;
  
            //home banner
            this.homeBanner = {
              home_banner_active: content.home_banner_active ? 1 : 0,
              home_banner_title: content.home_banner_title,
              home_banner_title_typing1: content.home_banner_title_typing1,
              home_banner_title_typing2: content.home_banner_title_typing2,
              home_banner_desc: content.home_banner_desc,
              home_banner_mp4: content.home_banner_mp4,
              home_banner_webm: content.home_banner_webm,
              home_banner_ogv: content.home_banner_ogv,
              home_banner_button1_label: content.home_banner_button1_label,
              home_banner_button1_url: content.home_banner_button1_url,
              home_banner_button2_label: content.home_banner_button2_label,
              home_banner_button2_url: content.home_banner_button2_url,
              home_banner_btn1_new_window: content.home_banner_btn1_new_window ? 1 : 0,
              home_banner_btn2_new_window: content.home_banner_btn2_new_window ? 1 : 0,
              home_banner_bg: this.pageDatas.data[0].header_img,
              home_banner_tooltip: content.home_banner_tooltip,
              home_banner_id: content.home_banner_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
            }
  
            //home about
            this.homeAbout = {
              home_about_active: content.home_about_active ? 1 : 0,
              home_about_title: content.home_about_title,
              home_about_subtitle: content.home_about_subtitle,
              home_about_desc: content.home_about_desc,
              home_about_btn_label: content.home_about_btn_label,
              home_about_btn_url: content.home_about_btn_url,
              home_about_front_img: this.pageDatas.data[0].body_img_path + content.home_about_front_img,
              home_about_back_img: this.pageDatas.data[0].body_img_path + content.home_about_back_img,
              home_about_btn_new_window: content.home_about_btn_new_window ? 1 : 0,
              home_about_tooltip: content.home_about_tooltip,
              home_about_id: content.home_about_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'') 
            }
  
            //home services
            this.homeService = {
              home_services_active: content.home_services_active ? 1 : 0,
              home_services_title: content.home_services_title,
              home_services_subtitle: content.home_services_subtitle,
              service_more_btn_label: content.service_more_btn_label,
              service_more_btn_pageUrl: content.service_more_btn_pageUrl,
              service_more_btn_new_window: content.service_more_btn_new_window,
              home_services_tooltip: content.home_services_tooltip,
              home_services_id: content.home_services_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
            }

            //home events
            this.homeEvents = {
              home_events_active: content.home_stories_active ? 1 : 0,
              home_events_tooltip: content.home_events_tooltip,
              home_events_title: content.home_events_title,
              home_events_subtitle: content.home_events_subtitle,
              home_events_more_btn_label: content.home_events_more_btn_label,
              home_events_more_btn_pageUrl: content.home_events_more_btn_pageUrl,
              home_events_id: content.home_events_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,''),
              home_events_more_btn_new_window: content.home_events_more_btn_new_window,
            }
  
            //home stories
            this.homeStories = {
              home_stories_active: content.home_stories_active ? 1 : 0,
              home_stories_top_img: this.pageDatas.data[0].body_img_path + content.home_stories_top_img,
              home_stories_middle_img: this.pageDatas.data[0].body_img_path + content.home_stories_middle_img,
              home_stories_bottom_img: this.pageDatas.data[0].body_img_path + content.home_stories_bottom_img,
              home_stories_title: content.home_stories_title,
              home_stories_subtitle: content.home_stories_subtitle,
              home_stories_desc: content.home_stories_desc,
              home_stories_more_btn_label: content.home_stories_more_btn_label,
              home_stories_more_btn_pageUrl: content.home_stories_more_btn_pageUrl,
              home_stories_more_btn_new_window: content.home_stories_more_btn_new_window,
              home_stories_tooltip: content.home_stories_tooltip,
              home_stories_id: content.home_stories_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
            }
  
            //home articles
            this.homeArticles = {
              home_articles_active: content.home_articles_active ? 1 : 0,
              home_articles_title: content.home_articles_title,
              home_articles_subtitle: content.home_articles_subtitle,
              home_articles_more_btn_label: content.home_articles_more_btn_label,
              home_articles_more_btn_pageUrl: content.home_articles_more_btn_pageUrl,
              home_articles_more_btn_new_window: content.home_articles_more_btn_new_window,
              home_articles_tooltip: content.home_articles_tooltip,
              home_articles_id: content.home_articles_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
            }
  
            this.homeMap = {
              hopme_map_active: content.hopme_map_active ? 1 : 0,
              hopme_map_link: content.hopme_map_link,
              hopme_map_tooltip: content.hopme_map_tooltip,
              hopme_map_id: content.hopme_map_tooltip.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,''),
              instagramFeed: content.home_instgram_feed,
              home_instgram_active: content.home_instgram_active,
              home_insta_feed_count: content.home_insta_feed_count,
          
            }
  
            this.access_token = this.homeMap['instagramFeed'];
            this.limit = this.homeMap['home_insta_feed_count'];

            this.getInstaFeed(this.access_token, this.limit);
  
            
            
          },
          error => console.log(error)
        )
      );
    }

    
    
    

  }


  getInstaFeed(access_token: any, limit: any){
    //getting instagram feed
    let headParams: object = {
      'fields': 'id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username',
      'access_token' : access_token,
      'limit': limit
    }
    this.subscriptions.push(
      this.instaService.getInstaFeed(headParams).subscribe(
        res => {
          if(res['data']){
            this.instaDatas = res['data'];
            this.instagramLoaded = true;
          }else if(res['error']){
            this.instagramLoaded = false;
          }
        }
      )
    );
  }


  

  ngAfterViewInit(){
    this.removingBodyHtmlClass();
  }

  
  //hiding ppnav
  hidePPNav(): void{
    if(document.body.contains(document.getElementById('pp-nav'))){
      var elem = document.getElementById('pp-nav');
      elem.remove();
    }
  }

  removingBodyHtmlClass(){
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove();
    html.removeAttribute('class');

    const body = document.getElementsByTagName('body')[0];
    body.classList.remove();
    body.removeAttribute('class');
  }

  ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.removingBodyHtmlClass();
    
  }

  
  
}
