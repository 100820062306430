<!-- Footer top -->
<!-- *ngIf="footerData.status == 1"-->
<div class="footer-top bg-white col-xs-12 px-0 footer_insta_feed" *ngIf="footerStatus == true">
    <div class="container-fluid">
        <div class="row">
            <div class="lqd-column col-md-12" style="padding: 0;">
                <owl-carousel-o [options]="customOptions" *ngIf="instagramLoaded == true">
                    <ng-container *ngFor="let insta of instaDatas">
                      <ng-template carouselSlide>
                        
                        <div class="lqd-column carousel-item insta-carousel p-0">
                            <a class="liquid-ig-feed-overlay" target="_blank" href="{{insta['permalink']}}">
                                <i *ngIf="insta['media_type'] == 'VIDEO'" class="fa fa-play" aria-hidden="true"></i>
                                <i *ngIf="insta['media_type'] == 'IMAGE' || insta['media_type'] == 'CAROUSEL_ALBUM'" class="fa fa-picture-o" aria-hidden="true"></i>
                                <p style="color: #fff;" *ngIf="insta['caption']">{{insta['caption'] | truncate:60:'..'}}</p>
                            </a>
                            <a href="{{insta['permalink']}}" target="_blank">
                                <img *ngIf="insta['media_type'] == 'IMAGE'" [src]="insta['media_url']" alt="Instagram Image 1">
                            <img *ngIf="insta['media_type'] == 'VIDEO'" [src]="insta['thumbnail_url']" alt="Instagram Image 1">
                            <img *ngIf="insta['media_type'] == 'CAROUSEL_ALBUM'" [src]="insta['media_url']" alt="Instagram Image 1">
                            </a>
                            
                        </div>
                        
                        </ng-template> 
                    </ng-container> 
                  </owl-carousel-o>


                  <div *ngIf="instagramLoaded == false" style="padding: 100px;">
                    <div class="loader" style="margin: 0 auto;"></div>
                </div>
                
            </div>
        </div>
    </div>
</div>
