import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor() { }
  //environment.cms_datas.rakKey
  encryptData(method: string, value: any){
    if(method == 'encrypt'){
      try{
        const data = CryptoJS.AES.encrypt(value, environment.cms_datas.rakKey).toString();
        return data.toString().replace(/\//g,'rak').replace(/\\/g,'sme');
      } catch (e) {
        console.error(e);
        return value;
      }
    }else if(method == 'decrypt'){
      try{
        const dec_val = value.toString().replace(/rak/g, '/' ).replace(/sme/g, /\\/);
        return CryptoJS.AES.decrypt(dec_val, environment.cms_datas.rakKey).toString(CryptoJS.enc.Utf8);
      } catch (e) {
        console.error(e);
        return value;
      }
    }else{
      return value;
    }
  }

}
