import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private meta: Meta, private titleService: Title, @Inject(DOCUMENT) private dom) { }

  generateTags(config) {
    config = {
      title: 'RAKSME',
      description: 'RAK-SME provides everything you need to start your own business through quick and easy steps and simplified procedures that pave the way for you to enter the business world and start up your business and economic activities, out of its belief in the ability of the UAE nationals and its desire to enable them to establish and develop their projects and to enhance their contribution to sustainable development in the Emirate. The Establishments services provided for its members include: exemptions of commercial license fees, economic and legal consultancy, business centers services, training and development programs and workshops, and discounts on various services, including participation in local and external exhibitions and industrial plots',
      keywords: 'raksme, rakchamber',
      image: '../../../assets/img/logo/logo.png',
      locale: 'en_US',
      ...config
    }

    this.titleService.setTitle(config.title+' | RAKSME');

    this.meta.updateTag({name: 'keywords', content: config.keywords});
    this.meta.updateTag({name: 'description', content: config.description});

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@raksme' });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: 'RAKSME' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: `https://www.raksme.ae/${config.slug}` });
    this.meta.updateTag({name: 'og:locale', content: config.locale});

    //setting up canonical url
    // const canURL = this.dom.URL;
    // const link: HTMLLinkElement = this.dom.createElement('link');
    // link.setAttribute('rel', 'canonical');
    // this.dom.head.appendChild(link);
    // link.setAttribute('href', canURL);
  }
}
