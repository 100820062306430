import { HomeComponent } from "../pages/mainPage/raksme.homeComponent";
import { AuthGuard } from "../guard/auth.guard";

export const MainRoutes = [
  { path: "rakhome", component: HomeComponent, data: { breadcrumb: "Home" } },

  { path: "ar", component: HomeComponent },
  { path: "home/ar", redirectTo: "/ar" },

  { path: "about-us", redirectTo: "/about-us/raksme", pathMatch: "full" },
  { path: "about-us/ar", redirectTo: "/about-us/raksme/ar", pathMatch: "full" },
  { path: "raksme", redirectTo: "/about-us/raksme", pathMatch: "full" },
  {
    path: "about-us/raksme",
    loadChildren: () =>
      import("../pages/about-us/rak-sme/raksme.raksmeModule").then(
        (raksme) => raksme.RakSmeModule
      ),
    data: { breadcrumb: "RAK-SME" },
  },

  { path: "raksme/ar", redirectTo: "/about-us/raksme/ar", pathMatch: "full" },
  {
    path: "about-us/raksme/ar",
    loadChildren: () =>
      import("../pages-ar/about-us/rak-sme/raksme.raksmeModule").then(
        (raksme) => raksme.RakSmeModuleAr
      ),
    data: { breadcrumb: "RAK-SME" },
  },

  { path: "partners", redirectTo: "/about-us/partners", pathMatch: "full" },
  {
    path: "about-us/partners",
    loadChildren: () =>
      import("../pages/about-us/partners/raksme.partnersModule").then(
        (raksme) => raksme.PartnersModule
      ),
    data: { breadcrumb: "Partners" },
  },

  {
    path: "partners/ar",
    redirectTo: "/about-us/partners/ar",
    pathMatch: "full",
  },
  {
    path: "about-us/partners/ar",
    loadChildren: () =>
      import("../pages-ar/about-us/partners/raksme.partnersModule").then(
        (raksme) => raksme.PartnersModuleAr
      ),
    data: { breadcrumb: "Partners" },
  },

  {
    path: "testimonials",
    redirectTo: "/about-us/testimonials",
    pathMatch: "full",
  },
  {
    path: "about-us/testimonials",
    loadChildren: () =>
      import("../pages/about-us/testimonials/raksme.testiModule").then(
        (raksme) => raksme.TestimonialsModule
      ),
    data: { breadcrumb: "Testimonials" },
  },

  {
    path: "testimonials/ar",
    redirectTo: "/about-us/testimonials/ar",
    pathMatch: "full",
  },
  {
    path: "about-us/testimonials/ar",
    loadChildren: () =>
      import("../pages-ar/about-us/testimonials/raksme.testiModule").then(
        (raksme) => raksme.TestimonialsModuleAr
      ),
    data: { breadcrumb: "Testimonials" },
  },

  {
    path: "chairmans-message",
    redirectTo: "/about-us/chairmans-message",
    pathMatch: "full",
  },
  {
    path: "about-us/chairmans-message",
    loadChildren: () =>
      import("../pages/about-us/chairman-message/raksme.chairMsgModule").then(
        (raksme) => raksme.ChairmansMessagModule
      ),
    data: { breadcrumb: "Chairman Message" },
  },

  {
    path: "chairmans-message/ar",
    redirectTo: "/about-us/chairmans-message/ar",
    pathMatch: "full",
  },
  {
    path: "about-us/chairmans-message/ar",
    loadChildren: () =>
      import(
        "../pages-ar/about-us/chairman-message/raksme.chairMsgModule"
      ).then((raksme) => raksme.ChairmansMessagModuleAr),
    data: { breadcrumb: "Chairman Message" },
  },

  {
    path: "directors-message",
    redirectTo: "/about-us/directors-message",
    pathMatch: "full",
  },
  {
    path: "about-us/directors-message",
    loadChildren: () =>
      import("../pages/about-us/director-message/raksme.dirMsgMsgModule").then(
        (raksme) => raksme.DirectorsMessagModule
      ),
    data: { breadcrumb: "Directors Message" },
  },

  {
    path: "directors-message/ar",
    redirectTo: "/about-us/directors-message/ar",
    pathMatch: "full",
  },
  {
    path: "about-us/directors-message/ar",
    loadChildren: () =>
      import(
        "../pages-ar/about-us/director-message/raksme.dirMsgMsgModule"
      ).then((raksme) => raksme.DirectorsMessagModuleAr),
    data: { breadcrumb: "Directors Message" },
  },

  //services
  {
    path: "our-services",
    data: { breadcrumb: "Our Services" },
    loadChildren: () =>
      import("../pages/our-services/raksme.ourservModule").then(
        (raksme) => raksme.OurServicesModule
      ),
  },
  {
    path: "our-services/ar",
    data: { breadcrumb: "Our Services" },
    loadChildren: () =>
      import("../pages-ar/our-services/raksme.ourservModule").then(
        (raksme) => raksme.OurServicesModuleAr
      ),
  },

  {
    path: "our-services/:slug",
    data: { breadcrumb: "Our Service" },
    loadChildren: () =>
      import("../pages/rak-services/raksme.serviceModule").then(
        (raksme) => raksme.RaksmeServicesModule
      ),
  },
  {
    path: "our-services/:slug/ar",
    data: { breadcrumb: "Our Service" },
    loadChildren: () =>
      import("../pages-ar/rak-services/raksme.serviceModule").then(
        (raksme) => raksme.RaksmeServicesModuleAr
      ),
  },

  {
    path: "our-services/:slug/form/:id",
    data: { breadcrumb: "Our Service" },
    loadChildren: () =>
      import("../pages/rak-forms/raksme.formsModule").then(
        (raksme) => raksme.RaksmeFormModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "our-services/:slug/form/:id/ar",
    data: { breadcrumb: "Our Service" },
    loadChildren: () =>
      import("../pages-ar/rak-forms/raksme.formsModule").then(
        (raksme) => raksme.RaksmeFormModuleAr
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "our-services/:slug/form/:id/edit",
    data: { breadcrumb: "Our Service" },
    loadChildren: () =>
      import("../pages/rak-forms-edit/raksme.formseditModule").then(
        (raksme) => raksme.RaksmeFormEditModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "our-services/:slug/form/:id/edit/ar",
    data: { breadcrumb: "Our Service" },
    loadChildren: () =>
      import("../pages-ar/rak-forms-edit/raksme.formseditModule").then(
        (raksme) => raksme.RaksmeFormEditModuleAr
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "success-stories",
    data: { breadcrumb: "Success Stories" },
    loadChildren: () =>
      import("../pages/success-stories/raksme.storiesModule").then(
        (raksme) => raksme.SuccessStoriesModule
      ),
  },
  {
    path: "success-stories/ar",
    data: { breadcrumb: "Success Stories" },
    loadChildren: () =>
      import("../pages-ar/success-stories/raksme.storiesModule").then(
        (raksme) => raksme.SuccessStoriesModuleAr
      ),
  },

  {
    path: "success-stories/:slug",
    data: { breadcrumb: "Success Stories" },
    loadChildren: () =>
      import(
        "../pages/success-stories/success-stories-inner/raksme.storiesDetailModule"
      ).then((raksme) => raksme.SuccessStoriesDetailModule),
  },
  {
    path: "success-stories/:slug/ar",
    data: { breadcrumb: "Success Stories" },
    loadChildren: () =>
      import(
        "../pages-ar/success-stories/success-stories-inner/raksme.storiesDetailModule"
      ).then((raksme) => raksme.SuccessStoriesDetailModuleAr),
  },

  //media center
  {
    path: "media-center",
    data: { breadcrumb: "Media Center" },
    loadChildren: () =>
      import("../pages/media-center/raksme.mediaModule").then(
        (raksme) => raksme.MediaCenterModule
      ),
  },
  {
    path: "media-center/ar",
    data: { breadcrumb: "Media Center" },
    loadChildren: () =>
      import("../pages-ar/media-center/raksme.mediaModule").then(
        (raksme) => raksme.MediaCenterModuleAr
      ),
  },

  {
    path: "news-articles",
    redirectTo: "/media-center/news-articles",
    pathMatch: "full",
  },
  {
    path: "media-center/news-articles",
    data: { breadcrumb: "News & Articles" },
    loadChildren: () =>
      import("../pages/media-center/news/raksme.newsModule").then(
        (raksme) => raksme.NewsModule
      ),
  },

  {
    path: "news-articles/ar",
    redirectTo: "/media-center/news-articles/ar",
    pathMatch: "full",
  },
  {
    path: "media-center/news-articles/ar",
    data: { breadcrumb: "News & Articles" },
    loadChildren: () =>
      import("../pages-ar/media-center/news/raksme.newsModule").then(
        (raksme) => raksme.NewsModuleAr
      ),
  },

  {
    path: "news-articles/:slug",
    redirectTo: "/media-center/news-articles/:slug",
    pathMatch: "full",
  },
  {
    path: "media-center/news-articles/:slug",
    data: { breadcrumb: "News & Articles" },
    loadChildren: () =>
      import(
        "../pages/media-center/news/news-details/raksme.newsDetailModule"
      ).then((raksme) => raksme.NewsDetailModule),
  },

  {
    path: "news-articles/:slug/ar",
    redirectTo: "/media-center/news-articles/:slug/ar",
    pathMatch: "full",
  },
  {
    path: "media-center/news-articles/:slug/ar",
    data: { breadcrumb: "News & Articles" },
    loadChildren: () =>
      import(
        "../pages-ar/media-center/news/news-details/raksme.newsDetailModule"
      ).then((raksme) => raksme.NewsDetailModuleAr),
  },

  {
    path: "event-calendar",
    redirectTo: "/media-center/event-calendar",
    pathMatch: "full",
  },
  {
    path: "media-center/event-calendar",
    data: { breadcrumb: "Events" },
    loadChildren: () =>
      import("../pages/media-center/events/raksme.eventModule").then(
        (raksme) => raksme.EventsModule
      ),
  },

  {
    path: "event-calendar/ar",
    redirectTo: "/media-center/event-calendar/ar",
    pathMatch: "full",
  },
  {
    path: "media-center/event-calendar/ar",
    data: { breadcrumb: "Events" },
    loadChildren: () =>
      import("../pages-ar/media-center/events/raksme.eventModule").then(
        (raksme) => raksme.EventsModuleAr
      ),
  },

  {
    path: "event-calendar/:slug",
    redirectTo: "/media-center/event-calendar/:slug",
    pathMatch: "full",
  },
  {
    path: "media-center/event-calendar/:slug",
    data: { breadcrumb: "Events" },
    loadChildren: () =>
      import(
        "../pages/media-center/events/events-details/raksme.eventDetailModule"
      ).then((raksme) => raksme.EventsDetailModule),
  },

  {
    path: "event-calendar/:slug/ar",
    redirectTo: "/media-center/event-calendar/:slug/ar",
    pathMatch: "full",
  },
  {
    path: "media-center/event-calendar/:slug/ar",
    data: { breadcrumb: "Events" },
    loadChildren: () =>
      import(
        "../pages-ar/media-center/events/events-details/raksme.eventDetailModule"
      ).then((raksme) => raksme.EventsDetailModuleAr),
  },

  { path: "gallery", redirectTo: "/media-center/gallery", pathMatch: "full" },
  {
    path: "media-center/gallery",
    data: { breadcrumb: "Photo Gallery" },
    loadChildren: () =>
      import("../pages/media-center/photo-gallery/raksme.photoModule").then(
        (raksme) => raksme.PhotoGalleryModule
      ),
  },

  {
    path: "gallery/ar",
    redirectTo: "/media-center/gallery/ar",
    pathMatch: "full",
  },
  {
    path: "media-center/gallery/ar",
    data: { breadcrumb: "Photo Gallery" },
    loadChildren: () =>
      import("../pages-ar/media-center/photo-gallery/raksme.photoModule").then(
        (raksme) => raksme.PhotoGalleryModuleAr
      ),
  },

  {
    path: "video-gallery",
    redirectTo: "/media-center/video-gallery",
    pathMatch: "full",
  },
  {
    path: "media-center/video-gallery",
    data: { breadcrumb: "Video Gallery" },
    loadChildren: () =>
      import("../pages/media-center/video-gallery/raksme.videoModule").then(
        (raksme) => raksme.VideoGalleryModule
      ),
  },

  {
    path: "video-gallery/ar",
    redirectTo: "/media-center/video-gallery/ar",
    pathMatch: "full",
  },
  {
    path: "media-center/video-gallery/ar",
    data: { breadcrumb: "Video Gallery" },
    loadChildren: () =>
      import("../pages-ar/media-center/video-gallery/raksme.videoModule").then(
        (raksme) => raksme.VideoGalleryModuleAr
      ),
  },

  {
    path: "publications",
    redirectTo: "/media-center/publications",
    pathMatch: "full",
  },
  {
    path: "media-center/publications",
    data: { breadcrumb: "Publicationss" },
    loadChildren: () =>
      import("../pages/media-center/publications/raksme.publiModule").then(
        (raksme) => raksme.PublicationsModule
      ),
  },

  {
    path: "publications/ar",
    redirectTo: "/media-center/publications/ar",
    pathMatch: "full",
  },
  {
    path: "media-center/publications/ar",
    data: { breadcrumb: "Publicationss" },
    loadChildren: () =>
      import("../pages-ar/media-center/publications/raksme.publiModule").then(
        (raksme) => raksme.PublicationsModuleAr
      ),
  },

  {
    path: "publications/:slug",
    redirectTo: "/media-center/publications/:slug",
    pathMatch: "full",
  },
  {
    path: "media-center/publications/:slug",
    data: { breadcrumb: "Publication" },
    loadChildren: () =>
      import(
        "../pages/media-center/publications/publication-detail/raksme.publiDetailModule"
      ).then((raksme) => raksme.PublicationDetailModule),
  },

  {
    path: "publications/:slug/ar",
    redirectTo: "/media-center/publications/:slug/ar",
    pathMatch: "full",
  },
  {
    path: "media-center/publications/:slug/ar",
    data: { breadcrumb: "Publication" },
    loadChildren: () =>
      import(
        "../pages-ar/media-center/publications/publication-detail/raksme.publiDetailModule"
      ).then((raksme) => raksme.PublicationDetailModuleAr),
  },

  {
    path: "contact-us",
    data: { breadcrumb: "Contact Us" },
    loadChildren: () =>
      import("../pages/contact-us/raksme.contactModule").then(
        (raksme) => raksme.ContactUsModule
      ),
  },
  {
    path: "contact-us/ar",
    data: { breadcrumb: "Contact Us" },
    loadChildren: () =>
      import("../pages-ar/contact-us/raksme.contactModule").then(
        (raksme) => raksme.ContactUsModuleAr
      ),
  },

  {
    path: "sign-up",
    data: { breadcrumb: "Sign Up" },
    loadChildren: () =>
      import("../pages/authentication/sign-up/raksme.SignupModule").then(
        (raksme) => raksme.SignUpModule
      ),
  },
  {
    path: "sign-up/ar",
    data: { breadcrumb: "Sign Up" },
    loadChildren: () =>
      import("../pages-ar/authentication/sign-up/raksme.SignupModule").then(
        (raksme) => raksme.SignUpModuleAr
      ),
  },

  {
    path: "sign-in",
    data: { breadcrumb: "Sign In" },
    loadChildren: () =>
      import("../pages/authentication/sign-in/raksme.signinModule").then(
        (raksme) => raksme.SignInModule
      ),
  },
  {
    path: "sign-in/ar",
    data: { breadcrumb: "Sign In" },
    loadChildren: () =>
      import("../pages-ar/authentication/sign-in/raksme.signinModule").then(
        (raksme) => raksme.SignInModuleAr
      ),
  },

  {
    path: "uaepass-registration",
    data: { breadcrumb: "Uaepass Registration" },
    loadChildren: () =>
      import(
        "../pages/authentication/uaepass-register/raksme.UaepassregisterModule"
      ).then((raksme) => raksme.UaepassRegisterModule),
  },

  {
    path: "uaepass-registration/ar",
    data: { breadcrumb: "Uaepass Registration" },
    loadChildren: () =>
      import(
        "../pages-ar/authentication/uaepass-register/raksme.UaepassregisterArModule"
      ).then((raksme) => raksme.UaepassRegisterArModule),
  },

  {
    path: "reset-password",
    data: { breadcrumb: "Reset Passowrd" },
    loadChildren: () =>
      import("../pages/authentication/reset-password/raksme.resetpModule").then(
        (raksme) => raksme.ResetPasswordModule
      ),
  },
  {
    path: "reset-password/ar",
    data: { breadcrumb: "Reset Passowrd" },
    loadChildren: () =>
      import(
        "../pages-ar/authentication/reset-password/raksme.resetpModule"
      ).then((raksme) => raksme.ResetPasswordModuleAr),
  },

  {
    path: "new-password/:md5",
    data: { breadcrumb: "New Password" },
    loadChildren: () =>
      import("../pages/authentication/new-password/raksme.newpModule").then(
        (raksme) => raksme.NewPasswordModule
      ),
  },
  {
    path: "new-password/:md5/ar",
    data: { breadcrumb: "New Password" },
    loadChildren: () =>
      import("../pages-ar/authentication/new-password/raksme.newpModule").then(
        (raksme) => raksme.NewPasswordModuleAr
      ),
  },

  {
    path: "my-account",
    data: { breadcrumb: "My Account" },
    loadChildren: () =>
      import("../pages/authentication/my-account/raksme.accountModule").then(
        (raksme) => raksme.MyAccountModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "my-account/ar",
    data: { breadcrumb: "My Account" },
    loadChildren: () =>
      import("../pages-ar/authentication/my-account/raksme.accountModule").then(
        (raksme) => raksme.MyAccountModuleAr
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "email-verification/:id",
    data: { breadcrumb: "Email Verification" },
    loadChildren: () =>
      import(
        "../pages/authentication/email-verification/raksme.emailVeriModule"
      ).then((raksme) => raksme.EmailVerificationModule),
  },
  {
    path: "email-verification/:id/ar",
    data: { breadcrumb: "Email Verification" },
    loadChildren: () =>
      import(
        "../pages-ar/authentication/email-verification/raksme.emailVeriModule"
      ).then((raksme) => raksme.EmailVerificationModuleAr),
  },

  {
    path: "verifiying-account/:md5",
    data: { breadcrumb: "Veriying Email" },
    loadChildren: () =>
      import(
        "../pages/authentication/verifying-account/raksme.veriAccpModule"
      ).then((raksme) => raksme.VerifyingAccountModule),
  },
  {
    path: "verifiying-account/:md5/ar",
    data: { breadcrumb: "Veriying Email" },
    loadChildren: () =>
      import(
        "../pages-ar/authentication/verifying-account/raksme.veriAccpModule"
      ).then((raksme) => raksme.VerifyingAccountModuleAr),
  },

  {
    path: "search",
    data: { breadcrumb: "Search" },
    loadChildren: () =>
      import("../pages/search/raksme.searchModule").then(
        (raksme) => raksme.SearchModule
      ),
  },
  {
    path: "search/ar",
    data: { breadcrumb: "Search" },
    loadChildren: () =>
      import("../pages-ar/search/raksme.searchModule").then(
        (raksme) => raksme.SearchModuleAr
      ),
  },

  //privacy policy
  {
    path: "privacy-policy",
    data: { breadcrumb: "Privacy Policy" },
    loadChildren: () =>
      import("../pages/privacy-policy/raksme.privacyModule").then(
        (raksme) => raksme.PrivacyPolicyModule
      ),
  },
  {
    path: "privacy-policy/ar",
    data: { breadcrumb: "Privacy Policy" },
    loadChildren: () =>
      import("../pages-ar/privacy-policy/raksme.privacyModule").then(
        (raksme) => raksme.PrivacyPolicyModuleAr
      ),
  },

  //terms & conditions
  {
    path: "terms-conditions",
    data: { breadcrumb: "Terms & Conditions" },
    loadChildren: () =>
      import("../pages/terms-conditions/raksme.termsModule").then(
        (raksme) => raksme.TermsConditionsModule
      ),
  },
  {
    path: "terms-conditions/ar",
    data: { breadcrumb: "Terms & Conditions" },
    loadChildren: () =>
      import("../pages-ar/terms-conditions/raksme.termsModule").then(
        (raksme) => raksme.TermsConditionsModuleAr
      ),
  },

  //404
  {
    path: "404",
    data: { breadcrumb: "Page not found" },
    loadChildren: () =>
      import("../pages/404/raksme.404Module").then(
        (raksme) => raksme.Page404Module
      ),
  },
  {
    path: "404/ar",
    data: { breadcrumb: "Page not found" },
    loadChildren: () =>
      import("../pages-ar/404/raksme.404Module").then(
        (raksme) => raksme.Page404ModuleAr
      ),
  },

  //uaepass
  {
    path: "uaepass",
    data: { breadcrumb: "Page not found" },
    loadChildren: () =>
      import("../pages/uaePass/raksme.uaepassModule").then(
        (raksme) => raksme.UaePassModule
      ),
  },

  //sitemap
  {
    path: "sitemap",
    data: { breadcrumb: "Sitemap" },
    loadChildren: () =>
      import("../pages/sitemap/raksme.sitemapModule").then(
        (raksme) => raksme.SitemapModule
      ),
  },
  {
    path: "sitemap/ar",
    data: { breadcrumb: "Sitemap" },
    loadChildren: () =>
      import("../pages-ar/sitemap/raksme.sitemapModule").then(
        (raksme) => raksme.SitemapModuleAr
      ),
  },

  { path: "home", redirectTo: "/", data: { breadcrumb: "Home" } },

  { path: "", component: HomeComponent },

  { path: "**", redirectTo: "404" },
];
