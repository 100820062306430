<div class="mainbar-wrap">
  <div class="row mainbar-row align-items-lg-stretch px-4">
    <div class="col pr-5">
      <div class="navbar-header">
        <a class="navbar-brand" routerLink="/{{logoUrl}}" rel="home">
          <span
            class="navbar-brand-inner"
            *ngIf="SettingsData?.site_logo != undefined"
          >
            <img
              class="logo-light"
              [src]="SettingsData?.site_logo"
              alt="raksme"
            />
            <img
              class="logo-dark"
              [src]="SettingsData?.site_logo_dark"
              alt="raksme"
            />
            <img
              class="mobile-logo-default"
              [src]="SettingsData?.site_logo_dark"
              alt="raksme"
            />
            <img
              class="logo-default"
              [src]="SettingsData?.site_logo"
              alt="raksme"
            />
          </span>

          <span
            class="navbar-brand-inner"
            *ngIf="SettingsData?.site_logo == undefined"
          >
            <ngx-skeleton-loader
              [theme]="{  width: '179px', height: '58px', 'background-color': '#808291', 'border-radius': '0', 'margin-bottom': '0', 'margin-top': '5px' }"
            ></ngx-skeleton-loader>
          </span>
        </a>
        <button
          type="button"
          class="navbar-toggle collapsed nav-trigger style-mobile"
          data-trigger="#main-header-collapse"
          aria-expanded="false"
          data-changeclassnames='{ "html": "mobile-nav-activated overflow-hidden" }'
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="bars">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="col">
      <div class="navbar-collapse" id="main-header-collapse">
        <div class="offcanvas-header mt-3">
          <button
            class="btn btn-outline-danger btn-close float-right menu-close-btn"
          >
            <i class="fa fa-times"></i>
          </button>
          <h5 class="py-2 text-white">&nbsp;</h5>
        </div>
        <ul
          id="primary-nav"
          class="main-nav nav align-items-lg-stretch justify-content-lg-center main-nav-hover-underline-1"
          data-submenu-options='{ "toggleType":"fade", "handler":"mouse-in-out" }'
          data-localscroll="true"
        >
          <li
            *ngFor="let menu of menus.data"
            [ngClass]="{'menu-item-has-children': menu.drop_menu == 1, 'position-applied':menu.drop_menu == 1 }"
            [routerLinkActive]="['active']"
          >
            <a
              *ngIf="menu.pages_id != 0 && menu.new_windows == 0 && menuLoaded == true"
              routerLink="/{{menu.link}}{{arUrl}}"
              [attr.target]="menu.pages_id != 0 && menu.new_windows == 1 ? '_blank' : '_self'"
              (click)="scrolltop()"
            >
              <span class="link-icon"></span>
              <span class="link-txt">
                <span class="link-ext"></span>
                <span class="txt"
                  >{{menu.name}}
                  <span class="submenu-expander" *ngIf="menu.drop_menu == 1">
                    <i class="fa fa-angle-down"></i>
                  </span>
                </span>
              </span>
            </a>

            <!--skeltpn-->
            <a *ngIf="menuLoaded == false">
              <span class="link-icon"></span>
              <span class="link-txt">
                <span class="link-ext"></span>
                <span class="txt"
                  ><ngx-skeleton-loader
                    [theme]="{  width: '80px', height: '15px', 'background-color': '#808291', 'margin-bottom': '0', 'margin-top': '5px' }"
                  ></ngx-skeleton-loader>
                  <span class="submenu-expander" *ngIf="menu.drop_menu == 1">
                    <i class="fa fa-angle-down"></i>
                  </span>
                </span>
              </span>
            </a>

            <a
              *ngIf="menu.pages_id == 0 && menu.new_windows == 0 && menuLoaded == true"
              routerLink="/{{menu.link}}{{arUrl}}"
              [attr.target]="menu.pages_id == 0 && menu.new_windows == 1 ? '_blank' : '_self'"
              (click)="scrolltop()"
            >
              <span class="link-icon"></span>
              <span class="link-txt">
                <span class="link-ext"></span>
                <span class="txt"
                  >{{menu.name}}
                  <span class="submenu-expander" *ngIf="menu.drop_menu == 1">
                    <i class="fa fa-angle-down"></i>
                  </span>
                </span>
              </span>
            </a>
            <!--skeltpn-->
            <a *ngIf="menuLoaded == false">
              <span class="link-icon"></span>
              <span class="link-txt">
                <span class="link-ext"></span>
                <span class="txt"
                  ><ngx-skeleton-loader
                    [theme]="{  width: '80px', height: '15px', 'background-color': '#808291', 'margin-bottom': '0', 'margin-top': '5px' }"
                  ></ngx-skeleton-loader>
                  <span class="submenu-expander" *ngIf="menu.drop_menu == 1">
                    <i class="fa fa-angle-down"></i>
                  </span>
                </span>
              </span>
            </a>

            <ul *ngIf="menu.drop_menu == 1" class="nav-item-children">
              <li
                *ngFor="let submenu of menu.submenu"
                [class.macSubLi]="subLiclass"
                [ngClass]="{'menu-item-has-children': submenu.drop_menu_third_level == 1, 'position-applied':submenu.drop_menu_third_level == 1 }"
              >
                <a
                  routerLink="/{{submenu.link}}{{arUrl}}"
                  [target]="submenu.new_windows == 1 ? '_blank' : '_self'"
                  (click)="scrolltop()"
                >
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                  <span class="link-icon"></span>
                  <span class="link-txt">
                    <span class="link-ext"></span>
                    <span class="txt"
                      >{{submenu.name}}
                      <span class="submenu-expander">
                        <i class="fa fa-angle-down"></i>
                      </span>
                    </span>
                  </span>
                </a>

                <!-- third level submenu -->
                <ul
                  *ngIf="submenu.drop_menu_third_level == 1"
                  class="nav-item-children third-submenu-class"
                >
                  <li
                    *ngFor="let third_menu of submenu.third_menu"
                    [class.macSubLi]="subLiclass"
                  >
                    <a
                      *ngIf="third_menu.active =='1'"
                      routerLink="/{{third_menu.link}}{{arUrl}}"
                      [target]="third_menu.new_windows == 1 ? '_blank' : '_self'"
                    >
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                      <span class="link-icon"></span>
                      <span class="link-txt">
                        <span class="link-ext"></span>
                        <span class="txt"
                          >{{third_menu.name}}
                          <span class="submenu-expander">
                            <i class="fa fa-angle-down"></i>
                          </span>
                        </span>
                      </span>
                    </a>
                    <a
                      *ngIf="third_menu.active =='0'"
                      [routerLink]="undefined"
                      [target]="third_menu.new_windows == 1 ? '_blank' : '_self'"
                    >
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                      <span class="link-icon"></span>
                      <span class="link-txt">
                        <span class="link-ext"></span>
                        <span class="txt"
                          >{{third_menu.name}}
                          <span class="submenu-expander">
                            <i class="fa fa-angle-down"></i>
                          </span>
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>

                <!-- End -->
              </li>
            </ul>
          </li>
        </ul>

        <!--skeleton-->
        <ul
          *ngIf="menuLoaded == false"
          id="primary-nav"
          class="main-nav nav align-items-lg-stretch justify-content-lg-center main-nav-hover-underline-1"
          data-submenu-options='{ "toggleType":"fade", "handler":"mouse-in-out" }'
          data-localscroll="true"
        >
          <li *ngFor="let menusk of [1, 2, 3, 5, 6]">
            <!--skeltpn-->
            <a *ngIf="menuLoaded == false">
              <span class="link-icon"></span>
              <span class="link-txt">
                <span class="link-ext"></span>
                <span class="txt"
                  ><ngx-skeleton-loader
                    [theme]="{  width: '80px', height: '15px', 'background-color': '#808291', 'margin-bottom': '0' }"
                  ></ngx-skeleton-loader>
                </span>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col text-right">
      <div class="header-module">
        <a
          routerLink="/{{SettingsData?.get_in}}{{arUrl}}"
          class="btn btn-solid text-uppercase btn-bordered border-thick font-size-12 lh-15 font-weight-bold ltr-sp-2 px-2"
          *ngIf="generalLabels?.get_in_touch != undefined"
        >
          <span>
            <span class="btn-txt">{{generalLabels?.get_in_touch}}</span>
          </span>
        </a>

        <!--skeleton-->
        <span class="btn-txt" *ngIf="generalLabels?.get_in_touch ==undefined "
          ><ngx-skeleton-loader
            [theme]="{  width: '153px', height: '48px', 'background-color': '#808291', 'margin-bottom': '0' }"
          ></ngx-skeleton-loader
        ></span>
      </div>
    </div>
  </div>
</div>
