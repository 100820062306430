<section class="vc_row pt-150 pb-50" data-section-luminance="light" *ngIf="pageData['home_stories_active'] == 1">
    <div class="container">
        <div class="row d-flex flex-wrap align-items-center">

            <div class="lqd-column col-md-6">
                <div class="row">
                    <div class="lqd-column col-md-12">
                        <header class="fancy-title  mb-2">
                            <h2 class="mt-2 mb-2 font-weight-normal lh-1"><strong [innerHTML]="pageData['home_stories_title'] | safe: 'html'">Success Stories</strong>
                            </h2>
                            <p class="h3 font-weight-normal mb-40  mt-4"><i [innerHTML]="pageData['home_stories_subtitle'] | safe: 'html'">who we help with ideas.</i></p>
                        </header>
                    </div>
                </div>
                <p class="font-size-18 lh-15 mb-3 pr-md-7 mr-md-7 mb-60" [innerHTML]="pageData['home_stories_desc'] | safe: 'html'">Lorem, ipsum dolor sit amet
                    consectetur adipisicing elit. Dolore eligendi, temporibus vel nemo totam deserunt
                    tenetur ab. Nihil a impedit voluptates, vero repudiandae enim accusantium minus eaque,
                    veritatis, velit necessitatibus.</p>
                <a routerLink="/{{pageData['home_stories_more_btn_pageUrl']}}{{langUrl}}" class="btn btn-solid text-uppercase semi-round border-thin font-weight-bold home_about_button" [target]="pageData['home_stories_more_btn_new_window'] == 1 ? '_blank':'_self'">
                    <span class="btn-txt" [innerHTML]="pageData['home_stories_more_btn_label'] | safe: 'html'">Discover More</span>
                </a>
            </div>

            <div class="lqd-column col-md-5 col-md-offset-1">
                <div class="lqd-parallax-images">
                    <div class="liquid-img-group-single">
                        <div class="liquid-img-group-img-container">
                            <div class="liquid-img-container-inner">
                                <figure><img [src]="pageData['home_stories_middle_img']" /></figure>
                            </div>
                        </div>
                    </div>
                    <div class="liquid-img-group-single w-75">
                        <div class="liquid-img-group-img-container">
                            <div class="liquid-img-container-inner">
                                <figure><img [src]="pageData['home_stories_top_img']" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            
        </div>
    </div>
    <div class="col-md-12" style="position: absolute;z-index: -1;bottom: 0;">
        <!-- <img
            src="../../../../../assets/img/pattern-10.png" style="max-width: 400px;opacity: 0.05;float: right;"> -->
        </div>
</section>