import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { WidgetsService } from "src/raksme/services/widgets/widgets.service";
import { Router } from "@angular/router";
import { EventsService } from "src/raksme/services/events/events.service";

@Component({
  selector: "raksme-menu",
  templateUrl: "./raksme.menuView.html",
  styleUrls: ["./raksme.menuStyle.css"],
})
export class MenuComponent implements OnInit, OnDestroy {
  public title = "menu component";

  private subscriptions: Subscription[] = [];
  public allEvents = [];
  public menus: any = [];
  public submenus: any = [];
  public errorMsg: string;
  public lang: string;
  public arUrl: string = "";
  public logoUrl: string = "";
  public generalLabels: any;
  public menuLoaded: boolean = false;

  animation = "pulse";
  intervalId;

  public SettingsData: any;

  @Input("generalLabel")
  set setGeneralLabel(_labelData: any) {
    this.generalLabels = _labelData;
  }

  @Input("siteSettings")
  set setSiteSettings(_siteSettings: any) {
    this.SettingsData = _siteSettings;
  }

  public subLiclass: boolean = false;

  constructor(
    private _headMenuServe: WidgetsService,
    private router: Router,
    private eventsService: EventsService
  ) {}

  ngOnInit() {
    if (this.router.url.includes("/ar") == true) {
      this.lang = "ar";
      this.arUrl = "/ar";
      this.logoUrl = "ar";
    } else {
      this.lang = "en";
    }
    this.getEventsList(13);
    this.getHeadDetails();

    //add class to the li submenu
    if (navigator.userAgent.indexOf("Mac") > 0) {
      this.subLiclass = true;
    }
  }

  getHeadDetails() {
    this.subscriptions.push(
      this._headMenuServe.getHeaderMenu("menus/header/" + this.lang).subscribe(
        (res) => {
          let activeEvents = this.modifyDataForTrainingSubMenu();
          res["data"].forEach((menuele) => {
            if (menuele.link == "our-services") {
              menuele.submenu?.forEach((submenuele) => {
                if (
                  submenuele.link ==
                  "our-services/registration-in-training-courses"
                ) {
                  submenuele.drop_menu_third_level = "1";
                  submenuele.third_menu = activeEvents;
                }
              });
            }
          });
          this.menus = res;
          this.menuLoaded = true;
        },
        (error) => console.log(error)
      )
    );
  }
  modifyDataForTrainingSubMenu() {
    let activeEvents;
    if (this.allEvents.length) {
      activeEvents = this.allEvents.map((data, index) => {
        return {
          id: index.toString(),
          name: data?.title,
          page_type: "Service",
          position: "Header",
          pages_id: "10",
          link: "media-center/event-calendar/" + data?.url_rewrite,
          drop_menu_third_level: "0",
          parent_id: "68",
          parent_name: "Training Programmes & Workshops",
          new_windows: "0",
          arrange: (index + 1).toString(),
          active: "1",
        };
      });
    } else {
      activeEvents = [
        {
          id: "69",
          name: `${
            this.lang == "en" ? "NO Active Events" : "لا توجد أحداث نشطة"
          }`,
          page_type: "Service",
          position: "Header",
          pages_id: "9",
          link: "our-services",
          drop_menu_third_level: "0",
          parent_id: "68",
          parent_name: "Training Programmes & Workshops",
          new_windows: "0",
          arrange: "1",
          active: "0",
        },
      ];
    }
    return activeEvents;
  }
  getEventsList(service_id) {
    let bodyData: object = {
      service: service_id,
    };
    this.subscriptions.push(
      this.eventsService
        .getAllEvents("services/events/" + this.lang, bodyData)
        .subscribe(
          (res) => {
            // this is for not showing the already viewing event
            let events = res["data"];
            // need to show first three active events
            let activeEvents = events.filter(
              (data) => new Date() < new Date(data?.date_en)
            );
            this.allEvents = activeEvents;
            if (this.allEvents == undefined) {
              this.allEvents = [];
            }
          },
          (error) => console.log(error)
        )
    );
  }

  scrolltop() {
    const element = document.querySelector("#rak-body");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
