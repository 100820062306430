import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./raksme.headerComponent";
import { SecBarComponent } from "./secondarybar/raksme.secBarComponent";
import { MenuComponent } from "./menu/raksme.menuComponent";
import { WidgetsService } from "src/raksme/services/widgets/widgets.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MyInterceptor } from "src/raksme/services/intreceptor/raksme.httpIntreceptor";
import { RouterModule } from "@angular/router";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SocialIconsComponent } from "./secondarybar/social-icons/social-icons.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { BsModalService, ModalModule } from "ngx-bootstrap/modal";

@NgModule({
  declarations: [
    HeaderComponent,
    SecBarComponent,
    MenuComponent,
    SocialIconsComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
  ],
  exports: [HeaderComponent, SecBarComponent, MenuComponent],
  providers: [
    WidgetsService,
    { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },
    NgxSpinnerService,
    BsModalService,
  ],
})
export class HeadersModule {}
