import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { GeneralLabelService } from "src/raksme/services/general-label/label.service";
import { Router } from "@angular/router";
import { LoginUser } from "src/raksme/models/auth/login_user";
import { AuthService } from "src/raksme/services/auth/auth.service";

@Component({
  selector: "raksme-sheaders",
  templateUrl: "./raksme.sheaderView.html",
  styleUrls: ["./raksme.sheaderStyle.css"],
})
export class ServicesHeaderComponent implements OnInit, OnDestroy {
  bgImage: any;
  title: string;
  formUrl: string;
  btnLabel: string;
  currentService: string;
  headerActive: any;
  newWindow: any = 0;
  serviceURL: string;
  public langUrl: any;
  public backUrl: any = "";
  public subscriptions: Subscription[] = [];
  public generalLabels: any = [];
  public lang: string = "en";
  public loadGlabel: boolean = false;

  public elseBtnUrl: string;
  public elseBtnLabel: string;
  public elseBtnNewWindow: number = 0;

  public hdclass: string = "";

  public formCountries: any;

  public loggedIn: boolean = false;

  public enableButton: string;

  @Input("header-data")
  set setHeaderData(_headerData: object) {
    this.bgImage = {
      backgroundImage: "url(" + _headerData["bgimage"] + ")",
    };

    this.title = _headerData["title"];
    this.formUrl = _headerData["formId"];
    this.btnLabel = _headerData["btnLabel"];
    this.currentService = _headerData["serviceName"];
    this.newWindow = _headerData["newWindow"];
    this.headerActive = _headerData["headerActive"];
    this.serviceURL = _headerData["serviceURL"];
    this.langUrl = _headerData["langUrl"];
    this.formCountries = _headerData["formCountries"];

    this.elseBtnUrl = _headerData["elseBtnUrl"];
    this.elseBtnLabel = _headerData["elseBtnLabel"];
    this.elseBtnNewWindow = _headerData["elseBtnNewWindow"];
    this.enableButton = _headerData["enableButton"];

    if (this.langUrl == "/ar") {
      this.backUrl = "../../";
      this.hdclass = "text-left pl-5 plar-5";
    } else {
      this.backUrl = "../";
      this.hdclass = "text-left pl-5 plen-5";
    }
    const storage_token = localStorage.getItem("RAKSME_TOKEN");
    if (storage_token) {
      //check user logged in or not
      this.auth_info$ = this.auth.getUserDetails("user");

      this.subscriptions.push(
        this.auth_info$.subscribe((res) => {
          this.userLoginDetails = res;

          this.loggedIn = this.userLoginDetails.logged_in;

          if (this.userLoginDetails.logged_in == true) {
            this.nationality = this.userLoginDetails.data[0].nationality;
            this.formCountriesArray = this.formCountries.split(",");
          }
        })
      );
    }
  }

  current_user$: Observable<LoginUser> = this.auth.user_info$;
  auth_info$: Observable<LoginUser>;
  public userLoginDetails: any = [];
  public nationality: any = "";
  public formCountriesArray: Array<string> = [];

  constructor(
    private labelService: GeneralLabelService,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes("/ar") == true) {
      this.lang = "ar";
    } else {
      this.lang = "en";
    }

    this.subscriptions.push(
      this.labelService.getLabels("general-label/" + this.lang).subscribe(
        (res) => {
          this.generalLabels = res;
          this.loadGlabel = true;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
