import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { WidgetsService } from 'src/raksme/services/widgets/widgets.service';
import { Router } from '@angular/router';

@Component({
  selector: 'raksme-copyright',
  templateUrl: './raksme.cfooterView.html'
})
export class CopyrightFooterComponent implements OnInit, OnDestroy {
  title = 'Copyright footer';

  private subscriptions: Subscription[] = [];
  public coptRightMenus: any = [];
  public copyrighFooter: any = [];

  public copyrighFooter_text: string;

  animation = 'pulse';
  intervalId;

  public lang: string = 'en';

  public langClass: string = "copyright_footer_en";

  constructor(private footerService: WidgetsService, private router: Router){}

  ngOnInit(){

    if(this.router.url.includes('/ar') == true){
      this.lang = 'ar';
      this.langClass = "copyright_footer_ar";
    }

    let mainFooterBody = {
      "footer_type": 3,
      "footer_index": "copyright-text",
      "footer_column" : 6
    };

    this.subscriptions.push(
      this.footerService.getFooterMenu('menus/copyright/'+this.lang).subscribe(
        res => {
          this.coptRightMenus = res;
        },
        error => console.log(error)
      ),
      this.footerService.getFooter('footer', mainFooterBody).subscribe(
        res => {
          this.copyrighFooter = res;
            this.copyrighFooter_text =  this.copyrighFooter.data[0].content.copyright_text;
            if(this.lang == 'ar'){
              this.copyrighFooter_text =  this.copyrighFooter.data[0].content.copyright_text_ar;
            }
        },
        error => console.log(error)
      )
    );

    //skelton animation
    this.intervalId = setInterval(() => {
      this.animation = this.animation === 'pulse' ? 'progress-dark' : 'pulse';
    }, 5000);
  }

  ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe());

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  
}
