import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';



//routing files
import { MainRoutes } from './routing/raksme.mainRouting';

//widgets modules
import { HeadersModule } from './widgets/headers/raksme.headerModule';
import { FootersModule } from './widgets/footers/footers.module';

//home page components
import { HomeComponent } from './pages/mainPage/raksme.homeComponent';
import { MainComponent } from './raksme.mainComponent';
import { HomeBannerComponent } from './pages/mainPage/homeSections/banner/raksme.homeBannerComponent';
import { HomeAboutComponent } from './pages/mainPage/homeSections/about/raksme.homeAboutComponent';
import { HomeServicesComponent } from './pages/mainPage/homeSections/services/raksme.homeServicesComponent';
import { HomeSuccessComponent } from './pages/mainPage/homeSections/stories/raksme.homeSuccessComponent';
import { HomeNewsComponent } from './pages/mainPage/homeSections/news/raksme.homeNewsComponent';
import { HomeFooterComponent } from './pages/mainPage/homeSections/footer/raksme.homeFooterComponent';
import { BreadcrumbService } from 'angular-crumbs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageHeadersModule } from './widgets/pages-headers/raksme.pheadersModule';
import { ServiceHeadersModule } from './widgets/services-headers/raksme.sheadersModule';
import { FormsHeadersModule } from './widgets/forms-headers/raksme.fheadersModule';
import { SeoService } from './services/seo/seo.service';
import { HomeEventsComponent } from './pages/mainPage/homeSections/events/raksme.homeEventsComponent';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';



const MainRouting: Routes = MainRoutes;


@NgModule({
  declarations: [
    MainComponent,
    HomeComponent,
    HomeBannerComponent,
    HomeAboutComponent,
    HomeServicesComponent,
    HomeSuccessComponent,
    HomeNewsComponent,
    HomeFooterComponent,
    HomeEventsComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(MainRouting, { scrollPositionRestoration: 'top', anchorScrolling: 'enabled' }),
    HeadersModule,
    FootersModule,
    BrowserAnimationsModule,
    PageHeadersModule,
    ServiceHeadersModule,
    FormsHeadersModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  exports: [
    RouterModule,
    PageHeadersModule
  ],
  providers: [
    BreadcrumbService,
    SeoService
  ],
  bootstrap: [MainComponent]
})
export class MainModule { }
