import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(applications: Array<any>, term: any): any {
    if (term === '') {
      return applications;
    }

    return applications.filter(source => {
      return source.application_number.toLowerCase().includes(term.toLowerCase()) || source.form_name.toLowerCase().includes(term.toLowerCase()) || source.updated_created.toLowerCase().includes(term.toLowerCase());
    });
  }
}
