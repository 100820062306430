import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap, } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { generalLabels } from 'src/raksme/models/general-labels/general-label';

@Injectable({
  providedIn: 'root'
})
export class GeneralLabelService {

  private base_url = environment.cms_datas.base_url; 
  private _url: string = "api/";
  api_url: string;

  constructor(public http:HttpClient) { }

  getLabels(param: string): Observable<generalLabels[]>{
    this.api_url = this.base_url + this._url + param;
    return this.http.get<generalLabels[]>(this.api_url)
                  .pipe(
                    switchMap(data => {
                      return of(data);
                    }),
                    catchError(this.errorHandler))
  }


  

  errorHandler(error: HttpErrorResponse){
    return throwError(error.message || "Server error");
  }
}
