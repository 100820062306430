import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'raksme-social-icons',
  templateUrl: './social-icons.component.html'
})
export class SocialIconsComponent implements OnInit {

  public socialLoaded: boolean;
  public headerSocialsData: any = [];

  @Input('socialLoaded')
  set setSocialLoaded(_socialLoaded: boolean){
    this.socialLoaded = _socialLoaded;
  }

  @Input('socialsData')
  set setsocialsData(_socialsData: any){
    this.headerSocialsData = _socialsData;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
