import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, of, BehaviorSubject } from "rxjs";
import { catchError, switchMap, take, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { signup, Countries } from "src/raksme/models/auth/signup";
import { LoginUser } from "src/raksme/models/auth/login_user";
import { Router } from "@angular/router";
import { AlertService } from "../notification/alert.service";
import { Alert } from "src/raksme/models/classes/alert";
import { EncryptionService } from "../encryption/encryption.service";
import { Applications } from "src/raksme/models/auth/user_applications";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private base_url = environment.cms_datas.base_url;
  private _url: string = "api/";
  api_url: string;

  public current_user: LoginUser = {
    status: 0,
    message: "not logged in",
    data: [],
    logged_in: false,
    user_name: "guest",
  };

  private lang: string = "";

  private cached_token;

  public user_subject = new BehaviorSubject(this.current_user);
  user_info$: Observable<LoginUser> = this.user_subject.asObservable();

  constructor(
    public http: HttpClient,
    private router: Router,
    private alert: AlertService,
    private encrypt: EncryptionService
  ) {}

  userRegistration(param: string, bodyData: object): Observable<signup[]> {
    this.api_url = this.base_url + this._url + param;
    return this.http.post<signup[]>(this.api_url, bodyData).pipe(
      switchMap((data) => {
        return of(data);
      }),
      catchError(this.errorHandler)
    );
  }

  userLogin(param: string, bodyData: object): Observable<any> {
    this.api_url = this.base_url + this._url + param;
    return this.http.post(this.api_url, bodyData).pipe(
      catchError(this.errorHandler),
      map((response: LoginUser) => {
        const access_token = response["token"];

        if (
          access_token &&
          access_token !== "non_authorized" &&
          response["status"] == 1
        ) {
          this.token = access_token;
          return true;
        } else if (response["status"] == 2) {
          return "MAPPED";
        } else if (response["status"] == 3 && access_token == "NOT_ACTIVE") {
          return "NOT_ACTIVE";
        } else if (response["status"] == 4 && access_token == "NOT_VERIFIED") {
          return "NOT_VERIFIED";
        } else {
          return false;
        }
      })
    );
  }

  submitUaepassData(param: string, bodyData: object): Observable<any> {
    this.api_url = this.base_url + this._url + param;
    return this.http.post(this.api_url, bodyData).pipe(
      catchError(this.errorHandler),
      map((response: LoginUser) => {
        const access_token = response["token"];

        if (
          access_token &&
          access_token !== "non_authorized" &&
          response["status"] == 1
        ) {
          this.token = access_token;
          sessionStorage.removeItem("uaepass_user_data");
          return true;
        } else if (response["status"] == 3 && access_token == "NOT_ACTIVE") {
          return "NOT_ACTIVE";
        } else if (response["status"] == 4 && access_token == "NOT_VERIFIED") {
          return "NOT_VERIFIED";
        } else {
          return response;
        }
      })
    );
  }

  userRegistrationUaePass(
    param: string,
    bodyData: object
  ): Observable<signup[]> {
    this.api_url = this.base_url + this._url + param;
    return this.http.post<signup[]>(this.api_url, bodyData).pipe(
      switchMap((data) => {
        const access_token = data["token"];
        if (data["status"] == 1 && access_token) {
          this.token = access_token;
          sessionStorage.removeItem("uaepass_user_data");
        }
        return of(data);
      }),
      catchError(this.errorHandler)
    );
  }

  getUserDetails(param: string): Observable<LoginUser> {
    this.api_url = this.base_url + this._url + param;
    let bodyData: object = {
      token: this.token,
    };
    return this.http.post<LoginUser>(this.api_url, bodyData).pipe(
      switchMap((response) => {
        response.logged_in = true;
        response.user_name = "member";
        this.user_subject.next(response);
        return of(response);
      }),
      catchError((error) => {
        this.user_subject.next(this.current_user);
        return of(this.current_user);
        // return throwError(error || 'Server Error!');
      })
    );
  }

  getUserApplications(param: string, bodyData): Observable<Applications> {
    this.api_url = this.base_url + this._url + param;
    return this.http.post<Applications>(this.api_url, bodyData).pipe(
      switchMap((response) => {
        return of(response);
      }),
      catchError((error) => {
        return throwError(error || "Server Error!");
      })
    );
  }

  getCountires(param: string): Observable<Countries> {
    this.api_url = this.base_url + this._url + param;
    return this.http.get<Countries>(this.api_url).pipe(
      switchMap((response) => {
        return of(response);
      }),
      catchError((error) => {
        return throwError(error || "Server Error!");
      })
    );
  }

  emailVerification(param: string, bodyData: object): Observable<signup[]> {
    this.api_url = this.base_url + this._url + param;
    return this.http.post<signup[]>(this.api_url, bodyData).pipe(
      switchMap((data) => {
        return of(data);
      }),
      catchError(this.errorHandler)
    );
  }

  verifyingEmail(param: string, bodyData: object): Observable<signup[]> {
    this.api_url = this.base_url + this._url + param;
    return this.http.post<signup[]>(this.api_url, bodyData).pipe(
      switchMap((data) => {
        return of(data);
      }),
      catchError(this.errorHandler)
    );
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server error");
  }

  //logout
  logout() {
    if (this.router.url.includes("/ar") == true) {
      this.lang = "/ar";
    }

    let api_url = `https://id.uaepass.ae/idshub/logout?redirect_uri=${
      "https://raksme.ae/" + "sign-in" + this.lang
    }`;
    this.token = null;
    this.current_user.logged_in = false;
    this.current_user.user_name = "guest";
    this.user_subject.next(this.current_user);
    if (localStorage.getItem("uaepassLogIn") == "1") {
      console.log("with uae pass");
      localStorage.removeItem("uaepassLogIn");
      window.location.href = api_url;
    } else {
      console.log("with noral login");

      this.router.navigateByUrl(`/sign-in${this.lang}`);
    }
    this.alert.alerts.next(new Alert("You have been signed out."));
  }
  //logout uaepass
  logoutUaePass() {
    let api_url =
      "https://id.uaepass.ae/idshub/logout?redirect_uri=http://localhost:4200/sign-in";
    return this.http.get(api_url).pipe(
      switchMap((response) => {
        return of(response);
      }),
      catchError((error) => {
        return throwError(error || "Server Error!");
      })
    );
  }

  get token() {
    if (this.cached_token) {
      return this.cached_token;
    }

    const storage_token = localStorage.getItem("RAKSME_TOKEN");

    if (storage_token) {
      let item = JSON.parse(storage_token);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        this.logout();
      } else {
        this.cached_token = item.value;
        const data = {
          value: this.cached_token,
          expiry: now.getTime() + 1800000,
        };
        localStorage.setItem("RAKSME_TOKEN", JSON.stringify(data));
        return item.value;
      }

      //1800000
    }
  }

  set token(value) {
    this.cached_token = value;
    if (value) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + 1800000,
      };
      localStorage.setItem("RAKSME_TOKEN", JSON.stringify(item));
    } else {
      localStorage.removeItem("RAKSME_TOKEN");
    }
  }
}
