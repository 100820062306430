import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Inject,
  APP_ID,
  PLATFORM_ID,
  Directive,
  ElementRef,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { BreadcrumbService, Breadcrumb } from "angular-crumbs";
import { isPlatformBrowser } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: "raksme-root",
  templateUrl: "./raksme.mainView.html",
  styleUrls: ["./raksme.mainStyle.css"],
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  routerSubscription: any;

  public macClass: boolean = false;
  public lang = "";

  constructor(
    private titleService: Title,
    private breadcrumbService: BreadcrumbService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (navigator.userAgent.indexOf("Mac") > 0) {
      this.macClass = true;
    }

    this.breadcrumbService.breadcrumbChanged.subscribe((crumbs) => {
      // this.titleService.setTitle(this.createTitle(crumbs));
    });

    if (localStorage.getItem("RAKSME_LANG") == "ar_AE") {
      const url = window.location.href;
      if (url.includes("/ar") == true) {
        //do nothing
      } else {
        // window.location.href = url + '/ar';
        const stringLength = url.length; // this will be 16
        if (url.charAt(stringLength - 1) == "/") {
          const location = "/ar";
          // window.location.href = location;
          this.router.navigateByUrl("/ar");
          console.log(url);
        } else {
          window.location.href = url + "/ar";
        }
      }
      this.lang = "/ar";
    } else if (localStorage.getItem("RAKSME_LANG") == "en_US") {
      const url = window.location.href;
      this.lang = "";
      if (url.includes("/ar") == true) {
        const newUrl = url.replace("/ar", "");

        window.location.href = newUrl;
      }
    }
  }

  ngAfterViewInit() {}

  private createTitle(routesCollection: Breadcrumb[]) {
    const title = "RAK SME";
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) {
      return title;
    }

    const routeTitle = this.titlesToString(titles);
    return `${routeTitle} ${title}`;
  }

  private titlesToString(titles) {
    return titles.reduce((prev, curr) => {
      return `${curr.displayName} - ${prev}`;
    }, "");
  }

  onActivate(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 50); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    }
  }

  ngOnDestroy() {
    this.breadcrumbService.breadcrumbChanged.unsubscribe();
  }
}
