<raksme-header></raksme-header>
<main  id="content" class="content" data-liquid-stack="true"
data-stack-options=''>
    <!-- <home-banner [pageContent] = "homeBanner"></home-banner>
    <home-about [pageContent] = "homeAbout"></home-about>
    <home-services [pageContent] = "homeService"></home-services>
    <home-sucessus [pageContent] = "homeStories"></home-sucessus>
    <home-news [pageContent] = "homeArticles"></home-news>
    <home-footer [pageContent] = "homeMap"></home-footer> -->
    
    <section class="vc_row fullheight d-flex flex-wrap align-items-center bordered-section-white fullheight-mobile" [attr.id]="homeBanner['home_banner_id'] ? homeBanner['home_banner_id'] : 'home'" [attr.data-tooltip]="homeBanner['home_banner_tooltip'] ? homeBanner['home_banner_tooltip'] : 'home1'" [attr.data-anchor]="homeBanner['home_banner_tooltip'] ? homeBanner['home_banner_tooltip'] : 'home1'">
        <home-banner [pageContent] = "homeBanner" style="width: 100% !important;"></home-banner>
    </section>

    <section *ngIf="homeAbout['home_about_active'] == 1" class="vc_row" data-section-luminance="light" [attr.id]="homeAbout['home_about_id'] ? homeAbout['home_about_id'] : 'about-us'" [attr.data-anchor]="homeAbout['home_about_tooltip'] ? homeAbout['home_about_tooltip'] : 'about-us'" [attr.data-tooltip]="homeAbout['home_about_tooltip'] ? homeAbout['home_about_tooltip'] : 'about-us'">
        <home-about [pageContent] = "homeAbout"></home-about>
    </section>

    <section *ngIf="homeService['home_services_active'] == 1" class="vc_row" data-section-luminance="light" [attr.id]="homeService['home_services_id'] ? homeService['home_services_id'] : 'our-services'" [attr.data-anchor]="homeService['home_services_tooltip'] ? homeService['home_services_tooltip'] : 'our-services'" [attr.data-tooltip]="homeService['home_services_tooltip'] ? homeService['home_services_tooltip'] : 'our-services'">
        <home-services [pageContent] = "homeService"></home-services>
    </section>

    <section *ngIf="homeEvents['home_events_active'] == 1" class="vc_row" data-section-luminance="light" [attr.id]="homeEvents['home_events_id'] ? homeEvents['home_events_id'] : 'our-services'" [attr.data-anchor]="homeEvents['home_events_tooltip'] ? homeEvents['home_events_tooltip'] : 'events'" [attr.data-tooltip]="homeEvents['home_events_tooltip'] ? homeEvents['home_events_tooltip'] : 'events'">
        <home-events [pageContent]="homeEvents"></home-events>
    </section>
    
    <section *ngIf="homeStories['home_stories_active'] == 1" class="vc_row" data-section-luminance="light" [attr.id]="homeStories['home_stories_id'] ? homeStories['home_stories_id'] : 'Success-Stories'" [attr.data-anchor]="homeStories['home_stories_tooltip'] ? homeStories['home_stories_tooltip'] : 'Success-Stories'" [attr.data-tooltip]="homeStories['home_stories_tooltip'] ? homeStories['home_stories_tooltip'] : 'Success-Stories'">
        <home-sucessus [pageContent] = "homeStories" [langURL]="langUrl"></home-sucessus>
    </section>

    <section *ngIf="homeArticles['home_articles_active'] == 1" class="vc_row" data-section-luminance="light" [attr.id]="homeArticles['home_articles_id'] ? homeArticles['home_articles_id'] : 'NewsArticles'" [attr.data-anchor]="homeArticles['home_articles_tooltip'] ? homeArticles['home_articles_tooltip'] : 'News & Articles'" [attr.data-tooltip]="homeArticles['home_articles_tooltip'] ? homeArticles['home_articles_tooltip'] : 'News & Articles'">
        <home-news [pageContent] = "homeArticles"></home-news>
    </section>

    <section *ngIf="homeMap['hopme_map_active'] == 1" class="vc_row" data-section-luminance="light" [attr.id]="homeMap['hopme_map_id'] ? homeMap['hopme_map_id'] : 'Location'" [attr.data-anchor]="homeMap['hopme_map_tooltip'] ? homeMap['hopme_map_tooltip'] : 'Location'" [attr.data-tooltip]="homeMap['hopme_map_tooltip'] ? homeMap['hopme_map_tooltip'] : 'Location'">
        <home-footer [pageContent] = "homeMap" [instaDatas]="instaDatas" [instagramLoaded]="instagramLoaded"></home-footer>
    </section>

    <script src="../../../assets/js/theme.min.js"></script>

</main>

<!--{"navigation":true, "prevNextButtons":false, "pageNumber":false, "prevNextLabels":{"prev":"Previous","next":"Next"}, "effect":"fadeScale"}-->