import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, switchMap, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { pageData, ServicesPageData } from "src/raksme/models/pages/page";

@Injectable({
  providedIn: "root",
})
export class PagesService {
  private base_url = environment.cms_datas.base_url;
  private _url: string = "api/";
  api_url: string;

  constructor(private http: HttpClient) {}

  //getpages
  getPages(param: string, bodyData: object): Observable<pageData[]> {
    this.api_url = this.base_url + this._url + param;

    return this.http.post<pageData[]>(this.api_url, bodyData).pipe(
      switchMap((data) => {
        return of(data);
      }),
      catchError(this.errorHandler)
    );
  }

  // get notifications
  getNotifications(param: string, member_id) {
    this.api_url = this.base_url + this._url + param;

    return this.http
      .post(this.api_url, { member_id: member_id.member_id })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.errorHandler)
      );
  }

  // mark as notifications
  markNotifications(param: string, member_id, notification_id) {
    this.api_url = this.base_url + this._url + param;

    return this.http
      .post(this.api_url, { member_id: member_id.member_id, notification_id })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.errorHandler)
      );
  }

  //getservicesinservicespage
  getServicesInPage(
    param: string,
    bodyData: object
  ): Observable<ServicesPageData[]> {
    this.api_url = this.base_url + this._url + param;

    return this.http.post<ServicesPageData[]>(this.api_url, bodyData).pipe(
      switchMap((data) => {
        return of(data);
      }),
      catchError(this.errorHandler)
    );
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server error");
  }
}
