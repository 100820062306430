// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cms_datas: {
    rakKey: "3bddcbdf-9011-46ba-9842-61994a018278",
    base_url: "https://raksme.ae/raksme-admin/",
  },
  uae_pass: {
    loginScreen:
      "https://id.uaepass.ae/idshub/authorize?response_type=code&client_id=raksme_web_prod&scope=urn:uae:digitalid:profile:general&state=HnlHOJTkTb66Y5H&redirect_uri=https://raksme.ae/uaepass/",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
