import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { GeneralLabelService } from "src/raksme/services/general-label/label.service";
import { WidgetsService } from "src/raksme/services/widgets/widgets.service";
import { Router } from "@angular/router";

@Component({
    selector: 'raksme-fheaders',
    templateUrl: './raksme.fheaderView.html',
    styleUrls: ['./raksme.fheaderStyle.css']
})
export class FormsHeaderComponent implements OnInit, OnDestroy {
  
  bgImage: any;
  title: string;
  subtitle: string;
  currentForm: string = '';
  public subscriptions: Subscription[] = [];
  public generalLabels: any = [];
  public lang: string = "en";
  public settingsData: any = {};

  public loadGlabel: boolean = false;

  @Input("header-data")
  set setHeaderData(_headerData: object){
    
    this.bgImage = {
      backgroundImage: 'url('+_headerData['bgimage']+')'
    };

    this.title = _headerData['title'];
    this.subtitle = _headerData['subtitle'];
    this.currentForm = _headerData['currentForm'];
  }

  constructor(
    private labelService: GeneralLabelService,
    private router: Router,
    private widgetService: WidgetsService
  ) {}

  ngOnInit():void{

    if (this.router.url.includes('/ar') == true){
      this.lang = 'ar';
    }else{
      this.lang = 'en';
    }

    this.subscriptions.push(
      this.labelService.getLabels('general-label/'+this.lang).subscribe(
        res => {
          this.generalLabels = res;
          this.loadGlabel = true;
        },
        error => {
          console.log(error);
        }
      )
    );

    this.subscriptions.push(
      this.widgetService.getSettings("website-settings/" + this.lang).subscribe(
        (res) => {
          this.settingsData = res["data"];
        },
        (error) => console.log(error)
      )
    );
  }

  ngOnDestroy():void{
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }
}
