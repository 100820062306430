import { Component, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'home-footer',
  templateUrl: './raksme.homeFooterView.html',
  styleUrls: ['./raksme.homeFooterStyle.css']
})
export class HomeFooterComponent {
  title = 'rak-sme';
  public pageData: object;
  public instaData: object;

  public customOptions: OwlOptions  = {};

  public instagramLoadedS: boolean = false;

  public mapLink="https://maps.google.com/maps?q=rak%20sme&t=&z=13&ie=UTF8&iwloc=&output=embed"

  @Input("pageContent")
  set setPagesData(_pageData: object){
    this.pageData = _pageData;
  }

  @Input("instaDatas")
  set instaDatas(_instaData: object){
    this.instaData = _instaData;
  }

  @Input("instagramLoaded")
  set instagramLoaded(_instagramLoadedS: boolean){
    this.instagramLoadedS = _instagramLoadedS;
    this.loadCarousal();
  }


  loadCarousal(){
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      lazyLoad: true,
      navSpeed: 800,
      autoplay: true,
      autoplaySpeed: 3000,
      autoplayHoverPause: true,
      navText: ['', ''],
      responsive: {
        0: {
          items: 2
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 6
        },
        1200: {
          items: 7
        }
      },
      nav: false
    };
  }

  
}
