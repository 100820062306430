<section class="vc_row pt-130 pb-100" data-section-luminance="light">
    <div class="container">
       <div class="row">
          <div class="lqd-column col-md-12">
             <header class="fancy-title text-center mb-6">
                <h2 class="mt-2 mb-2 font-weight-normal lh-1" data-fittext="true" data-fittext-options='{ "compressor": 0.65, "maxFontSize": 45, "minFontSize": 45 }'><strong [innerHTML]="pageData['home_events_title'] | safe:'html'"></strong></h2>
                <p class="h3 font-weight-normal mb-40 text-center mt-4"><i  [innerHTML]="pageData['home_events_subtitle'] | safe:'html'"></i></p>
             </header>
          </div>
       </div>
       <div class="row liquid-portfolio-list-row">

          <div class="col-md-4 col-sm-6 col-xs-12 masonry-item branding digital-design grid-hover-overlay" *ngFor="let events of allItems">
             <article class="ld-pf-item ld-pf-dark pf-bg-shadow pf-details-visible pf-details-boxed pf-details-pull-right pf-details-pull-up-half title-size-24 pf-details-h-str pf-details-v-end post-2021 liquid-portfolio type-liquid-portfolio status-publish format-gallery has-post-thumbnail hentry post_format-post-format-gallery liquid-portfolio-category-branding liquid-portfolio-category-digital-design liquid-portfolio-category-grid-hover-overlay">
                <div class="ld-pf-inner">
                   <div class="ld-pf-image">
                      <figure data-responsive-bg="true" data-parallax="true" data-parallax-options='{ "parallaxBG": true }'>
                         <img [src]="events['image']" alt="{{events['title']}}" />
                      </figure>
                   </div>
                   <div class="ld-pf-details py-3">
                      <div class="ld-pf-bg" style="background-color: #fff;" data-custom-animations="true" data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": "this", "easing": "easeInOutCirc", "duration": 850, "offDuration": 700, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                      </div>
                      <div class="ld-pf-details-inner" data-custom-animations="true" data-ca-options='{ "triggerHandler": "mouseenter", "triggerTarget": ".ld-pf-item", "triggerRelation": "closest", "offTriggerHandler": "mouseleave", "animationTarget": ".split-inner", "easing": "easeOutQuint", "duration": 850, "offDuration": 750, "startDelay": 280, "offDelay": 280, "delay": 60, "initValues": { "translateY": 0 }, "animations": { "translateY": -70 } }'>
                         <h3 class="ld-pf-title h4" data-split-text="true" data-split-options='{ "type": "lines" }' [innerHTML]="events['title'] | safe: 'html'"></h3>
                         <p data-split-text="true" data-split-options='{ "type": "lines" }' >{{events['date']}}</p>
                      </div>
                   </div>
                   <a routerLink="media-center/event-calendar/{{events['url_rewrite']}}{{langUrl}}" class="liquid-overlay-link"></a>
                   <!-- <div style="padding: 20px;background-color: rgba(193, 32, 38, 0.8);position: absolute;top: 0;width: 100%;"></div> -->
                </div>
             </article>
          </div>
          
          
       </div>

       <div class="row">
         <div class="col-md-12 text-center mt-5">
                    
            <a routerLink="/{{pageData['home_events_more_btn_pageUrl']}}/{{langUrl}}" class="btn btn-solid text-uppercase semi-round border-thin font-weight-bold" [target]="pageData['home_events_more_btn_new_window'] == 1 ? '_blank':'_self'">
            <span class="btn-txt">{{pageData['home_events_more_btn_label']}}</span>
        </a>
        
    </div>
       </div>
       
    </div>
    <div class="col-md-12" style="position: absolute;z-index: -1;bottom: 0;">
       <!-- <img src="../../../../../assets/img/pattern-10.png" style="max-width: 400px;opacity: 0.05;float: right;">
       <img src="../../../../../assets/img/pattern-10.png" style="max-width: 400px;opacity: 0.05;float: left;"> -->
    </div>
    <div class="col-md-12" style="position: absolute;z-index: -1;top: 0;">
       <!-- <img src="../../../../../assets/img/pattern-10.png" style="max-width: 400px;opacity: 0.05;float: right;">
       <img src="../../../../../assets/img/pattern-10.png" style="max-width: 400px;opacity: 0.05;float: left;"> -->
    </div>
 </section>