<div class="titlebar titlebar-md scheme-light text-center bg-center" [ngStyle]="bgImage">
    <!-- Header menu -->
    <raksme-header></raksme-header>
    <!-- end header menu -->

    <div class=" py-0 mt-100" *ngIf="title != ''">
      <div class="container titlebar-container" *ngIf="headerActive == 1">
          <div class="row titlebar-container">
              <div class="col-md-12 bg-white">
                  <div class="row">
                      <div class="col-md-12 pb-4 text-left pl-5" [class.plen-5]="lang == 'en'" [class.plar-5]="lang == 'ar'">
                        <breadcrumb #breadParent>
                           <ol class="breadcrumb reset-ul inline-nav comma-sep-li">
                              <li class="breadcrumb-item">
                                 <a href="" routerLink="/" *ngIf="loadGlabel == true">{{generalLabels.data?.home_label}}</a>
                                 <a href="" routerLink="/" *ngIf="loadGlabel == false"><ngx-skeleton-loader [theme]="{  width: '60px', height: '15px', 'margin-bottom': '-3px' }" ></ngx-skeleton-loader></a>
                              </li>
                              <li class="breadcrumb-item">
                                 <a href="" routerLink="/our-services" *ngIf="loadGlabel == true">{{generalLabels.data?.ourservices_label}}</a>
                                 <a href="" routerLink="/our-services" *ngIf="loadGlabel == false"><ngx-skeleton-loader [theme]="{  width: '60px', height: '15px', 'margin-bottom': '-3px' }" ></ngx-skeleton-loader></a>
                              </li>
                              <li class="breadcrumb-item active">
                                <span>{{  currentService  }}</span>
                             </li>
                              <!-- <ng-template ngFor let-route [ngForOf]="breadParent.breadcrumbs">
                                 
                                 <li *ngIf="!route.terminal" class="breadcrumb-item">
                                    <a href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
                                 </li>
                                 <li *ngIf="route.terminal" class="breadcrumb-item active">
                                    <span>{{  currentService  }}</span>
                                 </li>
                              </ng-template> -->
                           </ol>
                        </breadcrumb>
                      </div>

                    
                      <div class="col-md-9 col-sm-9 {{hdclass}}">
                          <h4 class="mt-3 font-weight-semibold">{{title}}</h4>
                      </div>
                      <div class="col-md-3 col-sm-3 text-center" [class.btn_container_shead_en]="lang == 'en'" [class.btn_container_shead_ar]="lang == 'ar'">

                        <div *ngIf="enableButton == '1'">
                          <ng-container *ngIf="loggedIn == false">
                            <a routerLink="{{backUrl}}{{serviceURL}}/form/{{formUrl}}{{langUrl}}" [target]="newWindow == 1 ? '_blank':'_self'" class="btn btn-default semi-round btn-bordered border-thin border-fade-red px-2 lqd-unit-animation-done" data-localscroll="true" data-localscroll-options='{"scrollBelowSection":true}'>
                                <span>
                                <span class="btn-txt">{{btnLabel}}</span>
                                </span>
                            </a>
                          </ng-container>

                          <ng-container *ngIf="loggedIn == true">
                            <a *ngIf="formCountriesArray.includes(nationality) == true || formCountriesArray.includes('') == true || formCountriesArray == undefined; else contactUS" routerLink="{{backUrl}}{{serviceURL}}/form/{{formUrl}}{{langUrl}}" [target]="newWindow == 1 ? '_blank':'_self'" class="btn btn-default semi-round btn-bordered border-thin border-fade-red px-2 lqd-unit-animation-done" data-localscroll="true" data-localscroll-options='{"scrollBelowSection":true}'>
                                <span>
                                <span class="btn-txt">{{btnLabel}}</span>
                                </span>
                            </a>
                          </ng-container>

                          <!--else condition-->
                          <ng-template #contactUS>
                            <a routerLink="/{{elseBtnUrl}}{{langUrl}}" [target]="elseBtnNewWindow == 1 ? '_blank':'_self'" class="btn btn-default semi-round btn-bordered border-thin border-fade-red px-2 lqd-unit-animation-done" data-localscroll="true" data-localscroll-options='{"scrollBelowSection":true}'>
                                <span>
                                <span class="btn-txt">{{elseBtnLabel}}</span>
                                </span>
                            </a>
                          </ng-template>
                        </div>

                        
                      </div>
                  </div>
              </div>
          </div>
      </div>


      
  </div>


  <div class=" py-0 mt-100" *ngIf="title == ''">
    <!--skeleton-->
    <div class="container titlebar-container">
        <div class="row titlebar-container">
            <div class="col-md-12 bg-white">
                <div class="row">
                    <div class="col-md-12 pb-4 text-left pl-5" [class.plen-5]="lang == 'en'" [class.plar-5]="lang == 'ar'">
                      <breadcrumb #breadParent>
                         <ol class="breadcrumb reset-ul inline-nav comma-sep-li">
                            <li class="breadcrumb-item">
                               
                               <a href="" routerLink="/"><ngx-skeleton-loader [theme]="{  width: '60px', height: '15px', 'margin-bottom': '-3px' }" ></ngx-skeleton-loader></a>
                            </li>
                            <li class="breadcrumb-item">
                               
                               <a href="" routerLink="/our-services"><ngx-skeleton-loader [theme]="{  width: '60px', height: '15px', 'margin-bottom': '-3px' }" ></ngx-skeleton-loader></a>
                            </li>
                            <li class="breadcrumb-item active">
                              <span><ngx-skeleton-loader [theme]="{  width: '70px', height: '15px', 'margin-bottom': '-3px' }" ></ngx-skeleton-loader></span>
                           </li>
                            
                         </ol>
                      </breadcrumb>
                    </div>

                  
                    <div class="col-md-9 {{hdclass}}">
                        <h4 class="mt-3 font-weight-semibold"><ngx-skeleton-loader [theme]="{  width: '300px', height: '35px', 'margin-bottom': '-3px' }" ></ngx-skeleton-loader></h4>
                    </div>
                    <div class="col-md-3 text-center">
                        <ngx-skeleton-loader [theme]="{  width: '144px', height: '55px', 'margin-bottom': '-3px' }" ></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

 </div>