import { Injectable, OnInit } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth/auth.service";
import { LoginUser } from "../models/auth/login_user";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, OnInit {
  current_user$: Observable<LoginUser> = this.auth.user_info$;
  auth_info$: Observable<LoginUser>;

  public arSlug = "";

  constructor(public auth: AuthService, public router: Router) {
    this.auth_info$ = this.auth.getUserDetails("user");
  }

  ngOnInit() {
    this.auth_info$ = this.auth.getUserDetails("user");
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.auth.token) {
      if (state?.url.split("/").includes("registration-in-training-courses")) {
        this.router.navigateByUrl("media-center/event-calendar" + this.arSlug);
        return true;
      }
      return true;
    } else {
      if (this.router.url.includes("/ar") == true) {
        this.arSlug = "/ar";
      }
      if (state?.url.split("/").includes("registration-in-training-courses")) {
        this.router.navigateByUrl("media-center/event-calendar" + this.arSlug);
        return true;
      }
      this.router.navigate(["/sign-in" + this.arSlug], {
        queryParams: { redirectTo: state.url },
      });
      return false;
    }
  }
}
