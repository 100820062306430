<section class="vc_row pt-150 pb-50" data-section-luminance="light" *ngIf="pageData['home_services_active'] == 1">
    <div class="container">
        <div class="row">
            <div class="lqd-column col-md-12">
                <header class="fancy-title text-center mb-6">
                    <h2 class="mt-2 mb-2 font-weight-normal lh-1"><strong [innerHTML]="pageData['home_services_title'] | safe: 'html'">Rak SME services</strong></h2>
                    <p class="h3 font-weight-normal mb-40 text-center mt-4"><i [innerHTML]="pageData['home_services_subtitle'] | safe: 'html'">supports youth enterpreneurs.</i></p>
                </header>
            </div>
            <div class="row">
                <div class="lqd-column col-md-12">
                    <div class="carousel-container carousel-shadow-active carousel-nav-left carousel-nav-md carousel-dots-style1">
                        <div class="carousel-items row"
                            data-lqd-flickity='' style="direction: ltr;">

                            <!--{"cellAlign":"left","prevNextButtons":true,"pageDots":false,"groupCells":false,"wrapAround":true,"pauseAutoPlayOnHover":false, "autoPlay": true}-->

                            <!-- Carousel item -->
                            <div *ngFor="let service of serviceDatas.data" class="lqd-column col-md-3 col-sm-6 carousel-item">
                                <div class="iconbox iconbox-icon-shadow iconbox-circle iconbox-xl iconbox-heading-sm px-2">
                                    <div class="iconbox-icon-wrap">
                                        <a routerLink="/our-services/{{service['url']}}{{langUrl}}"><span
                                            class="iconbox-icon-container bg-white"><span
                                                class="iconbox-icon-hover-bg bg-gradient-primary-br"></span>
                                                <img class="" [src]="service['carousal_icon']">
                                        </span></a>
                                    </div>
                                    <div class="contents">
                                        <a routerLink="/our-services/{{service['url']}}{{langUrl}}"><h3 class="font-weight-semibold " [innerHTML]="service['carousal_title'] | safe: 'html'">
                                        </h3></a>
                                    </div>
                                </div>
                            </div>

                            

                            

                        </div>
                    </div>
                </div>
                <div class="col-md-12 text-center mt-5">
                    
                        <a routerLink="/{{pageData['service_more_btn_pageUrl']}}/{{langUrl}}" class="btn btn-solid text-uppercase semi-round border-thin font-weight-bold" [target]="pageData['service_more_btn_new_window'] == 1 ? '_blank':'_self'">
                        <span class="btn-txt">{{pageData['service_more_btn_label']}}</span>
                    </a>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12" style="position: absolute;z-index: -1;bottom: 0;">
        <!-- <img src="../../../../../assets/img/pattern-10.png" style="max-width: 400px;opacity: 0.05;float: left;"> -->
    </div>
</section>