import { Component, Input } from '@angular/core';

@Component({
  selector: 'home-sucessus',
  templateUrl: './raksme.homeSuccessView.html',
  styleUrls: ['./raksme.homeSuccessStyle.css']
})
export class HomeSuccessComponent {
  title = 'rak-sme';

  public pageData: object;
  public langUrl: string = '';

  @Input("pageContent")
  set setPagesData(_pageData: object){
    this.pageData = _pageData;
  }

  @Input("langURL")
  set setLangUrl(_url: string){
    this.langUrl = _url;
  }
}
