import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { WidgetsService } from 'src/raksme/services/widgets/widgets.service';
import { Router } from '@angular/router';
import { InstagramFeedService } from 'src/raksme/services/instagram-feed/instagram-feed.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'raksme-instafeed',
  templateUrl: './raksme.instaView.html',
  styleUrls: ['./raksme.instaStyle.css']
})
export class InstaComponent implements OnInit, OnDestroy {
  title = 'instagram footer';
  private subscriptions: Subscription[] = [];

  public footerData: any = [];
  public access_token: any = '';
  public limit: any = 10;
  public lang: string;

  public instaDatas: any = [];

  public footerStatus: boolean = true;

  public customOptions: OwlOptions  = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    lazyLoad: true,
    navSpeed: 800,
    autoplay: true,
    autoplaySpeed: 5000,
    autoplayHoverPause: true,
    navText: ['<i class="fas fa-caret-right"></i>', '<i class="fas fa-caret-left"></i>'],
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2,
        nav: false
      },
      740: {
        items: 3
      },
      940: {
        items: 6
      },
      1200: {
        items: 7
      }
    },
    
  };


  public instagramLoaded: boolean = false;

  constructor(private footerService: WidgetsService, private router: Router, private instaService: InstagramFeedService){}

  ngOnInit(): void {

    if(this.router.url.includes('/ar') == true){
      this.lang = 'ar';
    }else{
      this.lang = 'en';
    }

    let instaFooterBody = {
      "footer_type": 1,
      "footer_index": "instagram",
      "footer_column" : 7
    };

    this.subscriptions.push(

      this.footerService.getFooter('footer', instaFooterBody).subscribe(
        res => {
          this.footerData = res;
          
          if(this.footerData.status == 1){
            this.footerStatus = true;
            this.access_token = this.footerData.data[0].content.top_instgram_feed;
            this.limit = this.footerData.data[0].content.feed_count;

            this.getInstaFeed(this.access_token, this.limit);

          }else{
            this.footerStatus = false;
          }
          
          
        }
      )
    );

    
    

  }

  getInstaFeed(access_token: any, limit: any){
    //getting instagram feed
    let headParams: object = {
      'fields': 'id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username',
      'access_token' : access_token,
      'limit': limit
    }
    this.subscriptions.push(
      this.instaService.getInstaFeed(headParams).subscribe(
        res => {
          if(res['data']){
            this.instaDatas = res['data'];
            this.loadCarousal();
            this.instagramLoaded = true;
          }else if(res['error']){
            this.instagramLoaded = false;
          }
        }
      )
    );
  }


  loadCarousal(){
    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      lazyLoad: true,
      navSpeed: 800,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      navText: ['<i class="fas fa-caret-right"></i>', '<i class="fas fa-caret-left"></i>'],
      nav: true,
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2,
          nav: false
        },
        740: {
          items: 3
        },
        940: {
          items: 6
        },
        1200: {
          items: 7
        }
      },
      
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  
}
