import { Component, OnInit, OnDestroy } from '@angular/core';
import { WidgetsService } from 'src/raksme/services/widgets/widgets.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Alert } from 'src/raksme/models/classes/alert';
import { AlertService } from 'src/raksme/services/notification/alert.service';
import { AlertType } from 'src/raksme/models/enum/alert-type.enum';
import { FormsService } from 'src/raksme/services/forms/forms.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'raksme-mainfooter',
  templateUrl: './raksme.mainfootView.html',
  host: {
    '(window:resize)': 'onResize($event)'
  }
  // styleUrls: ['./raksme.mainfootStyle.css']
})
export class MainfooterComponent implements OnInit, OnDestroy {
  title = 'main footer';
  private subscriptions: Subscription[] = [];
  public mainFooter1:any = [];
  public mainFooter2:any = [];
  public mainFooter3:any = [];
  public mainFooter4:any = [];
  public mainFooterMenu: any = [];
  public footerSocials: any = [];

  animation = 'pulse';
  intervalId;

  //mainfooter1 variables
  public mainFooter1_logo: string;
  public mainFooter1_text: string;
  public mainFooter1_qr_code: string;
  public mainFooter1_qr_code_text: string;

  //mainfooter 2 variables
  public mainFooter2_title: string;

  //mainfooter 3 variables
  public mainFooter3_title: string;
  public mainFooter3_address: string;

  //mainfoote 4 variablels
  public mainFooter4_title: string;
  public email_field_placeholder: string;
  public email_field_success: string;
  public email_field_error: string;
  public message_field_placeholder: string;
  public message_field_success: string;
  public message_field_error: string;
  public submit_field_label: string;
  public success_message: string;
  public error_message: string;

  public footerForm: FormGroup;
  
  public lang: string;

  public alerts: Array<Alert> = [];

  public footer4id: any;
  public postDataRes: any =[];

  public langUrl: string = '';

  public showHideF: boolean = false;

  constructor(private footerService: WidgetsService, private router:Router, private fb: FormBuilder, private alertService: AlertService, private formService: FormsService, private spinner:NgxSpinnerService){}

  ngOnInit(){

    if(window.innerWidth < 768){
      this.showHideF = true;
    }else{
      this.showHideF = false;
    }

    //alert 
    this.subscriptions.push(
      this.alertService.alerts.subscribe(alert => {
        this.alerts.push(alert);
      })
    );

    if(this.router.url.includes('/ar') == true){
      this.lang = 'ar';
      this.langUrl = '/ar';
    }else{
      this.lang = 'en';
    }

    //column 1
    let mainFooter1Body = {
      "footer_type": 2,
      "footer_index": "column-1",
      "footer_column" : 1
    };

    //column 2
    let mainFooter2Body = {
      "footer_type": 2,
      "footer_index": "column-2",
      "footer_column" : 2
    };

    //column 3
    let mainFooter3Body = {
      "footer_type": 2,
      "footer_index": "column-3",
      "footer_column" : 3
    };

    //column 4
    let mainFooter4Body = {
      "footer_type": 2,
      "footer_index": "column-4",
      "footer_column" : 4
    };

    this.subscriptions.push(
      
      this.footerService.getFooter('footer', mainFooter1Body).subscribe(
        res => {
          this.mainFooter1 = res;

            if(this.mainFooter1.status == 1){
              if(this.lang == 'ar'){
                this.mainFooter1_logo=  this.mainFooter1.data[0].img_path + this.mainFooter1.data[0].content.column1_footer_img_ar;
                this.mainFooter1_text= this.mainFooter1.data[0].content.column1_footer_text_ar;
                this.mainFooter1_qr_code= this.mainFooter1.data[0].img_path + this.mainFooter1.data[0].content.column1_footer_qr_code_ar;
                this.mainFooter1_qr_code_text= this.mainFooter1.data[0].content.column1_footer_qr_text_ar;
              }else{
                this.mainFooter1_logo=  this.mainFooter1.data[0].img_path + this.mainFooter1.data[0].content.column1_footer_img;
                this.mainFooter1_text= this.mainFooter1.data[0].content.column1_footer_text;
                this.mainFooter1_qr_code= this.mainFooter1.data[0].img_path + this.mainFooter1.data[0].content.column1_footer_qr_co;
                this.mainFooter1_qr_code_text= this.mainFooter1.data[0].content.column1_footer_qr_text;
              }
            }

            
          
        },
        error => console.log(error)
      )

    );


    this.subscriptions.push(

      this.footerService.getFooter('footer', mainFooter2Body).subscribe(
        res => {
          this.mainFooter2 = res;
             
          if(this.mainFooter2.status == 1){
            if(this.lang == 'ar'){
              this.mainFooter2_title=  this.mainFooter2.data[0].content.column2_footer_column_title_ar;
            }else{
              this.mainFooter2_title=  this.mainFooter2.data[0].content.column2_footer_column_title;
            }
          }

            
        },
        error => console.log(error)
      ),
    );

    this.subscriptions.push(

      this.footerService.getFooter('footer', mainFooter3Body).subscribe(
        res => {
          this.mainFooter3 = res;

            if(this.mainFooter3.status == 1){
              if(this.lang == 'ar'){
                this.mainFooter3_title=  this.mainFooter3.data[0].content.column3_footer_column_title_ar;
                this.mainFooter3_address=  this.mainFooter3.data[0].content.column3_footer_address_ar;
              }else{
                this.mainFooter3_title=  this.mainFooter3.data[0].content.column3_footer_column_title;
                this.mainFooter3_address=  this.mainFooter3.data[0].content.column3_footer_address;
              }
            }

            
        },
        error => console.log(error)
      ),
    );

    this.subscriptions.push(
      this.footerService.getFooter('footer', mainFooter4Body).subscribe(
        res => {
          this.mainFooter4 = res;

          if(this.mainFooter4.status == 1){
            this.footer4id = this.mainFooter4.data[0].id;
          if(this.lang == 'ar'){
            this.mainFooter4_title =  this.mainFooter4.data[0].content.column4_footer_column_title_ar;
            this.mainFooter4_title =  this.mainFooter4.data[0].content.column4_footer_column_title_ar;
            this.email_field_placeholder= this.mainFooter4.data[0].content.email_field_placeholder_ar;
            this.email_field_success= this.mainFooter4.data[0].content.email_field_success_ar;
            this.email_field_error= this.mainFooter4.data[0].content.email_field_error_ar;
            this.message_field_placeholder= this.mainFooter4.data[0].content.message_field_placeholder_ar;
            this.message_field_success= this.mainFooter4.data[0].content.message_field_success_ar;
            this.message_field_error= this.mainFooter4.data[0].content.message_field_error_ar;
            this.submit_field_label= this.mainFooter4.data[0].content.submit_field_label_ar;
            this.success_message= this.mainFooter4.data[0].content.success_message_ar;
            this.error_message= this.mainFooter4.data[0].content.error_message_ar;
          }else{
            this.mainFooter4_title =  this.mainFooter4.data[0].content.column4_footer_column_title;
            this.email_field_placeholder= this.mainFooter4.data[0].content.email_field_placeholder;
            this.email_field_success= this.mainFooter4.data[0].content.email_field_success;
            this.email_field_error= this.mainFooter4.data[0].content.email_field_error;
            this.message_field_placeholder= this.mainFooter4.data[0].content.message_field_placeholder;
            this.message_field_success= this.mainFooter4.data[0].content.message_field_success;
            this.message_field_error= this.mainFooter4.data[0].content.message_field_error;
            this.submit_field_label= this.mainFooter4.data[0].content.submit_field_label;
            this.success_message= this.mainFooter4.data[0].content.success_message;
            this.error_message= this.mainFooter4.data[0].content.error_message;
          }
          }

          
        },
        error => console.log(error)
      ),
    );

    this.subscriptions.push(
      this.footerService.getFooterMenu('menus/footer/'+this.lang).subscribe(
        res => {
          this.mainFooterMenu = res;
        },
        error => console.log(error)
      ),
    );

    this.subscriptions.push(
      this.footerService.getFooterSocials('socials/footer').subscribe(
        res => {
          this.footerSocials = res;
        },
        error => console.log(error)
      )
    );

    //defining footer form
    this.footerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
      message: ['', [Validators.required, Validators.pattern(/^[a-z A-Z0-9_.-]*$/)]]
    });

    //skelton animation
    this.intervalId = setInterval(() => {
      this.animation = this.animation === 'pulse' ? 'progress-dark' : 'pulse';
    }, 5000);

  }

  get Email(){
    return this.footerForm.get('email');
  }
  get Message(){
    return this.footerForm.get('message');
  }

  //submitting footer form
  SubmitData(){
    if(!this.footerForm.valid){
      this.SignupAlertDanger(this.error_message);
    }else{
      this.spinner.show();
      const formsData: FormData = new FormData();
      formsData.append('email', this.footerForm.get('email').value);
      formsData.append('message', this.footerForm.get('message').value);
      formsData.append('footer_id', this.footer4id);

      this.subscriptions.push(
        this.formService.insertFormData('footer/post_enquiry', formsData).subscribe(
          res => {
            this.postDataRes = res;
            if(this.postDataRes.status == 0){
              this.SignupAlertDanger(this.error_message);
              this.spinner.hide();
            }else if(this.postDataRes.status == 1){
              this.SignupAlertSuccess(this.success_message);
              this.footerForm.reset();
              this.spinner.hide();
            }
            
          },
          error => {
            console.log(error);
            this.SignupAlertDanger(this.error_message);
            this.spinner.hide();
          }
        )

      );

      
    }
    
  }

  SignupAlertDanger(message: string): void{
    const failedSignupAlert = new Alert(message, AlertType.Danger);
    this.alertService.alerts.next(failedSignupAlert);
  }
  SignupAlertSuccess(message: string): void{
    const failedSignupAlert = new Alert(message, AlertType.Success);
    this.alertService.alerts.next(failedSignupAlert);
  }

  onResize(event){
    console.log(event.target.innerWidth); // window width
    if(event.target.innerWidth < 768){
      this.showHideF = true;
    }else{
      this.showHideF = false;
    }
  }

  ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
