<section class="vc_row pt-150" data-section-luminance="light" *ngIf="pageData['hopme_map_active'] == 1">
    <div class="col-md-12" style="position: absolute;top: 0;">
        <!-- <img src="../../../../../assets/img/pattern-10.png" style="max-width: 400px;opacity: 0.05;float: left;margin-right: 20px;"> -->
    </div>
    <div class="mt-5">
        <div class="container-fluid map-container-fluid">
            <div class="row">
                <div class="lqd-column col-md-12 px-4 pt-45 pb-10 bg-white box-shadow-1 pull-up">
                    <!-- Google map -->
                    <div class="mapouter">
                        <div class="gmap_canvas">
                            <iframe style="width: 100%; height: 500px;" id="gmap_canvas" [src]="pageData['hopme_map_link'] | safe: 'resourceUrl'" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div>
                            <!--c-->
                    </div>
                    <!-- Map end -->
                </div>
            </div>
        </div>
    </div>

    <div class="footer-top bg-white col-xs-12 px-0" *ngIf="pageData['home_instgram_active'] == 1">
        <div class="container-fluid">
            <div class="row">
                <div class="lqd-column col-md-12 p-0">
                    <owl-carousel-o [options]="customOptions" *ngIf="instagramLoadedS == true">
                        <ng-container *ngFor="let insta of instaData">
                          <ng-template carouselSlide>
                            
                            <div class="lqd-column carousel-item insta-carousel p-0">
                                <a class="liquid-ig-feed-overlay" target="_blank" href="{{insta['permalink']}}">
                                    <i *ngIf="insta['media_type'] == 'VIDEO'" class="fa fa-play" aria-hidden="true"></i>
                                    <i *ngIf="insta['media_type'] == 'IMAGE' || insta['media_type'] == 'CAROUSEL_ALBUM'" class="fa fa-picture-o" aria-hidden="true"></i>
                                    <p style="color: #fff;" *ngIf="insta['caption']">{{insta['caption'] | truncate:60:'..'}}</p>
                                </a>
                                <a href="{{insta['permalink']}}" target="_blank" class="insta_hoverlay">
                                    <img *ngIf="insta['media_type'] == 'IMAGE'" [src]="insta['media_url']" alt="Instagram Image 1">
                                    <img *ngIf="insta['media_type'] == 'VIDEO'" [src]="insta['thumbnail_url']" alt="Instagram Image 1">
                                    <img *ngIf="insta['media_type'] == 'CAROUSEL_ALBUM'" [src]="insta['media_url']" alt="Instagram Image 1">
                                </a>
                                
                            </div>
                            
                            </ng-template> 
                        </ng-container> 
                      </owl-carousel-o>
    
    
                      <div *ngIf="instagramLoadedS == false" style="padding: 100px;">
                        <div class="loader" style="margin: 0 auto;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <raksme-footer></raksme-footer>

</section>