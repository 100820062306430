<section
  class="vc_row py-5 fullheight d-flex flex-wrap align-items-center bordered-section-white home-banner-bg fullheight-mobile"
  [ngStyle]="bgImage"
>
  <div class="vidContain" *ngIf="isMobile == false">
    <div class="vid">
      <video autoplay muted id="myVideo" poster="">
        <!--{{pageData['home_banner_bg']}}-->
        <source
          [src]="pageData['home_banner_mp4'] | safe: 'url'"
          type="video/mp4"
        />
        <source
          [src]="pageData['home_banner_webm'] | safe: 'url'"
          type="video/webm"
        />
        <source
          [src]="pageData['home_banner_ogv'] | safe: 'url'"
          type="video/ogv"
        />
      </video>
    </div>
  </div>

  <div class="liquid-row-overlay"></div>
  <div class="container">
    <div class="row">
      <div
        class="col-md-offset-1 text-center"
        [class.col-md-8]="lang == 'ar'"
        [class.col-md-10]="lang == 'en'"
      >
        <div class="ld-fancy-heading">
          <!-- <img src="../../../../../assets/img/pattern-9.gif" class="img-fluid pattern"> -->

          <h1 class="text-white mb-40 font-weight-bold">
            <span class="ld-fh-txt">
              <span
                [innerHTML]="pageData['home_banner_title'] | safe: 'html'"
              ></span
              >&nbsp;<span class="text-white" id="raktyping"></span
            ></span>
          </h1>
        </div>
        <div class="ld-fancy-heading">
          <p class="text-fade-white-07 mb-55 lh-15">
            <span
              class="ld-fh-txt"
              [innerHTML]="pageData['home_banner_desc'] | safe: 'html'"
              >We provides everything you need to start your own business</span
            >
          </p>
        </div>
        <a
          routerLink="/{{pageData['home_banner_button1_url']}}"
          [target]="pageData['home_banner_btn1_new_window'] ? '_blank' : '_self'"
          class="btn btn-solid text-uppercase semi-round border-thin font-weight-bold font-mobile-12px"
        >
          <span
            class="btn-txt"
            [innerHTML]="pageData['home_banner_button1_label'] | safe: 'html'"
            >Our Services</span
          >
        </a>
        <a
          routerLink="/{{pageData['home_banner_button2_url']}}"
          [target]="pageData['home_banner_btn2_new_window'] ? '_blank' : '_self'"
          class="btn btn-default text-uppercase semi-round btn-bordered border-thin text-white border-fade-white-03 font-weight-bold font-mobile-12px home_banner_btn_2"
        >
          <span
            class="btn-txt"
            [innerHTML]="pageData['home_banner_button2_label'] | safe: 'html'"
            >Discover More</span
          >
        </a>
      </div>
    </div>
  </div>
</section>
