import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";

//models
import {
  EventsCategories,
  Events,
} from "src/raksme/models/media-centres/news-events";
import { clientCategory } from "src/raksme/models/raksme-service/rakservices";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  private base_url = environment.cms_datas.base_url;
  private _url: string = "api/";
  api_url: string;

  constructor(public http: HttpClient) {}

  getCategories(param: string): Observable<EventsCategories[]> {
    this.api_url = this.base_url + this._url + param;
    return this.http.get<EventsCategories[]>(this.api_url).pipe(
      switchMap((data) => {
        return of(data);
      }),
      catchError(this.errorHandler)
    );
  }

  getAllEvents(param: string, bodyData: object): Observable<Events[]> {
    this.api_url = this.base_url + this._url + param;
    return this.http.post<Events[]>(this.api_url, bodyData).pipe(
      switchMap((data) => {
        return of(data);
      }),
      catchError(this.errorHandler)
    );
  }

  getEventsTypes(param: string): Observable<clientCategory[]> {
    this.api_url = this.base_url + this._url + param;
    return this.http.get<clientCategory[]>(this.api_url).pipe(
      switchMap((data) => {
        return of(data);
      }),
      catchError(this.errorHandler)
    );
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server error");
  }
}
