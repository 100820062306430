import { Component, OnInit, OnDestroy } from '@angular/core';
import { ScriptService } from 'src/raksme/services/scripts/script.service';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WidgetsService } from 'src/raksme/services/widgets/widgets.service';

declare var $: any;
declare var jQuery: any;
declare var gtag;

@Component({
  selector: 'raksme-footer',
  templateUrl: './raksme.footerView.html',
  styleUrls: ['./raksme.footerStyle.css']
})
export class FooterComponent implements OnInit, OnDestroy {
  title = 'rak-sme';

  public subscriptions: Subscription[] = [];

  private s1: any;
  private s2: any;
  private s3: any;
  private s4: any;
  private s5: any;
  private s6: any;

  private cs: any;
  private cs2: any;
  private cs3: any;

  private gtag: any;

  public settingsData: any = [];
  public analyticsid: any = '';
 
  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document, private router: Router, private widgetService: WidgetsService){
  }

  

  ngOnInit(){

    let bodyData: object = {
      column: "ga_view_id"
    }
    this.subscriptions.push(
      this.widgetService.getWSettings('settings', bodyData).subscribe(
        res => {
          this.settingsData = res;
          this.analyticsid = this.settingsData.data[0].ga_view_id;

          //gtag
          this.gtag = this._renderer2.createElement('script');
          this.gtag.src = `https://www.googletagmanager.com/gtag/js?id=${this.analyticsid}`;
          this.gtag.async = true;
          this._renderer2.appendChild(this._document.head, this.gtag);

          //
          const navEndEvent$ = this.router.events.pipe(
            filter(e=> e instanceof NavigationEnd)
          );
          navEndEvent$.subscribe((e: NavigationEnd)=>{
            gtag('config', this.analyticsid, {'page_path':e.urlAfterRedirects});
          });
        },
        error => console.log(error)
      )
    )

    this.loadScripts();

    
    
    
  }


  loadScripts() {

      const versionUpdate = (new Date()).getTime();

      this.s1 = this._renderer2.createElement('script');
      this.s1.src = location.origin+'/assets/vendors/modernizr.min.js?v=' + versionUpdate;
      this.s1.type = `text/javascript`;
      this.s1.async = true;
      this.s1.charset = 'utf-8';
      this._renderer2.appendChild(this._document.body, this.s1);

      this.s2 = this._renderer2.createElement('script');
      this.s2.src = location.origin+'/assets/vendors/jquery.min.js?v=' + versionUpdate;
      this.s2.type = `text/javascript`;
      this.s2.async = false;
      this.s2.charset = 'utf-8';
      this._renderer2.appendChild(this._document.body, this.s2);

      this.s3 = this._renderer2.createElement('script');
      this.s3.src = location.origin+'/assets/js/theme-vendors.js?v=' + versionUpdate;
      this.s3.type = `text/javascript`;
      this.s3.async = false;
      this.s3.charset = 'utf-8';
      this._renderer2.appendChild(this._document.body, this.s3);

      this.s4 = this._renderer2.createElement('script');
      this.s4.src = location.origin+'/assets/vendors/pagePiling/dist/jquery.pagepiling.min.js?v=' + versionUpdate;
      this.s4.type = `text/javascript`;
      this.s4.async = false;
      this.s4.charset = 'utf-8';
      this._renderer2.appendChild(this._document.body, this.s4);

      this.s5 = this._renderer2.createElement('script');
      this.s5.src = location.origin+'/assets/js/theme.min.js?v=' + versionUpdate;
      this.s5.type = `text/javascript`;
      this.s5.async = false;
      this.s5.charset = 'utf-8';
      this._renderer2.appendChild(this._document.body, this.s5);

      this.s6 = this._renderer2.createElement('script');
      this.s6.src = location.origin+'/assets/js/custom.js?v=' + versionUpdate;
      this.s6.type = `text/javascript`;
      this.s6.async = false;
      this.s6.charset = 'utf-8';
      this._renderer2.appendChild(this._document.body, this.s6);

      if(this.router.url.includes('/ar') == true){
        this.cs = this._renderer2.createElement('link');
        this.cs.href = location.origin+'/assets/css/ar-style.css';
        this.cs.rel = `stylesheet`;
        this.cs.charset = 'utf-8';
        this._renderer2.appendChild(this._document.head, this.cs);
      }else{
        this.cs = this._renderer2.createElement('link');
        this.cs.href = location.origin+'/assets/css/en-style.css';
        this.cs.rel = `stylesheet`;
        this.cs.charset = 'utf-8';
        this._renderer2.appendChild(this._document.head, this.cs);
      }

      this.cs2 = this._renderer2.createElement('link');
        this.cs2.href = location.origin+'/assets/css/themes/original.css';
        this.cs2.rel = `stylesheet`;
        this.cs2.charset = 'utf-8';
        this._renderer2.appendChild(this._document.head, this.cs2);

        this.cs3 = this._renderer2.createElement('link');
        this.cs3.href = location.origin+'/assets/css/themes/virtus-4.css';
        this.cs3.rel = `stylesheet`;
        this.cs3.charset = 'utf-8';
        this._renderer2.appendChild(this._document.head, this.cs3);

      
      
    
  }

  

  ngOnDestroy(){
    
    this._renderer2.removeChild(this._document.body, this.s1);
    this._renderer2.removeChild(this._document.body, this.s2);
    this._renderer2.removeChild(this._document.body, this.s3);
    this._renderer2.removeChild(this._document.body, this.s4);
    this._renderer2.removeChild(this._document.body, this.s5);
    this._renderer2.removeChild(this._document.body, this.s6);

    this._renderer2.removeChild(this._document.head, this.cs);
    this._renderer2.removeChild(this._document.head, this.cs2);
    this._renderer2.removeChild(this._document.head, this.cs3);

    this._renderer2.removeChild(this._document.head, this.gtag);

    this.subscriptions.forEach(sub => sub.unsubscribe());
    
  }
}
