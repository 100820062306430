import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstaComponent } from './instagram-feed/raksme.instaComponent';
import { MainfooterComponent } from './main-footer/raksme.mainfooComponent';
import { CopyrightFooterComponent } from './copyright-footer/raksme.cfooterComponent';
import { FooterComponent } from './raksme.footerComponent';
import { HttpClientModule } from '@angular/common/http';
import { WidgetsService } from 'src/raksme/services/widgets/widgets.service';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ScriptService } from 'src/raksme/services/scripts/script.service';
import { SafeElementPipe } from 'src/raksme/pipes/safelement.pipe';
import { SafePipe } from 'src/raksme/pipes/safeUrl.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AlertService } from 'src/raksme/services/notification/alert.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TruncatePipe } from 'src/raksme/pipes/truncate';
import { SearchPipe } from 'src/raksme/pipes/search.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LazyImgDirective } from 'src/raksme/directive/raksme.lazyDirective';


@NgModule({
  declarations: [
    FooterComponent,
    InstaComponent,
    MainfooterComponent,
    CopyrightFooterComponent,
    SafeElementPipe,
    SafePipe,
    TruncatePipe,
    SearchPipe,
    LazyImgDirective
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    CarouselModule,
    NgxSpinnerModule
  ],
  exports: [
    FooterComponent,
    InstaComponent,
    MainfooterComponent,
    CopyrightFooterComponent,
    SafeElementPipe,
    SafePipe,
    CarouselModule,
    TruncatePipe,
    SearchPipe,
    LazyImgDirective
  ],
  providers: [
    WidgetsService,
    ScriptService,
    AlertService
  ]
})
export class FootersModule { }
