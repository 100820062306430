<section class="vc_row pt-130 pb-100" data-section-luminance="light" *ngIf="pageData['home_articles_active'] == 1">
    <div class="container">
        <div class="row">
            <div class="lqd-column col-md-6 col-md-offset-3 text-center mb-10">
                <h2 class="mt-2 mb-2 font-weight-normal lh-1"><strong [innerHTML]="pageData['home_articles_title'] | safe: 'html'">Interesting articles</strong></h2>
                <p class="h3 font-weight-normal mb-40 mt-4"><i [innerHTML]="pageData['home_articles_subtitle'] | safe: 'html'">in news & events.</i></p>
            </div>
            <div class="lqd-column col-md-12">
                <div class="liquid-blog-posts">

                    <div class="row row-eq" *ngIf="newsData.status == 1">

                        <div class="lqd-column col-md-4 branding masonry-item mb-60" *ngFor="let news of allItems">
                            <article class="liquid-lp liquid-blog-item liquid-blog-item-grid liquid-blog-scheme-dark">
                                <figure class="liquid-lp-media"><a routerLink="/media-center/news-articles/{{news['url_rewrite']}}{{langUrl}}"><img [src]="news['image']" alt="{{news['title']}}"></a></figure>
                                <div class="liquid-blog-item-inner"><a routerLink="/media-center/news-articles/{{news['url_rewrite']}}{{langUrl}}" class="liquid-overlay-link" [innerHTML]="news['title'] | safe: 'html'"></a>
                                    <header class="liquid-lp-header">
                                        <h2 class="liquid-lp-title font-weight-bold h3 size-sm" [innerHTML]="news['title'] | safe: 'html'"></h2>
                                        <div class="liquid-lp-details"><time class="liquid-lp-date text-uppercase size-sm"  [attr.datetime]="news['date']">{{news['date']}}</time> </div>
                                    </header>
                                    <div class="liquid-lp-excerpt">
                                        <p [innerHTML]="news['content'] | safe: 'html'"></p>
                                    </div>
                                    <footer class="liquid-lp-footer"><a routerLink="/media-center/news-articles/{{news['url_rewrite']}}{{langUrl}}" class="btn btn-naked text-uppercase ltr-sp-1 size-sm font-weight-bold liquid-lp-read-more"><span><span class="btn-line btn-line-before"></span><span class="btn-txt">{{generalLabels['article_detail']}}</span><span class="btn-line btn-line-after"></span></span></a></footer>
                                </div>
                            </article>
                        </div>

                        
                        
                    </div>

                    
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mt-3">
                <a routerLink="/media-center/{{pageData['home_articles_more_btn_pageUrl']}}{{langUrl}}" class="btn btn-solid text-uppercase semi-round border-thin font-weight-bold" [target]="pageData['home_articles_more_btn_new_window'] == 1 ? '_blank':'_self'">
                    <span class="btn-txt" [innerHTML]="pageData['home_articles_more_btn_label'] | safe: 'html'">See all articles</span>
                </a>
            </div>
        </div>
    </div>
    <div class="col-md-12" style="position: absolute;z-index: -1;bottom: 0;">
        <!-- <img
            src="../../../../../assets/img/pattern-10.png" style="max-width: 400px;opacity: 0.05;float: right;"> -->
        </div>
</section>