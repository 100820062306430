<div
  class="titlebar titlebar-md scheme-light text-center bg-center"
  [ngStyle]="bgImage"
>
  <!-- Header menu -->
  <raksme-header></raksme-header>
  <!-- end header menu -->

  <div class="py-0 mt-100" *ngIf="title != ''">
    <div class="container titlebar-container">
      <div class="row titlebar-container">
        <div class="col-md-12 bg-white">
          <div class="row">
            <div
              class="col-md-12 pb-4 text-left pl-5"
              [class.plen-5]="lang == 'en'"
              [class.plar-5]="lang == 'ar'"
            >
              <breadcrumb>
                <ol class="breadcrumb reset-ul inline-nav comma-sep-li">
                  <li class="breadcrumb-item">
                    <a href="" routerLink="/" *ngIf="loadGlabel == true"
                      >{{generalLabels.data?.home_label}}</a
                    >
                    <a href="" routerLink="/" *ngIf="loadGlabel == false"
                      ><ngx-skeleton-loader
                        [theme]="{  width: '60px', height: '15px', 'margin-bottom': '-3px' }"
                      ></ngx-skeleton-loader
                    ></a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="" routerLink="/our-services"
                      >{{generalLabels.data?.ourservices_label}}</a
                    >
                    <a
                      href=""
                      routerLink="/our-services"
                      *ngIf="loadGlabel == false"
                      ><ngx-skeleton-loader
                        [theme]="{  width: '60px', height: '15px', 'margin-bottom': '-3px' }"
                      ></ngx-skeleton-loader
                    ></a>
                  </li>

                  <li class="breadcrumb-item active">
                    <span>{{ currentForm }}</span>
                  </li>
                </ol>
              </breadcrumb>
            </div>
            <div
              *ngIf="lang == 'en'"
              class="col-md-8 pl-md-4 pl-0 d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start w-100"
            >
              <div
                class="col-md-8 col-sm-8 text-left pl-4"
                [class.plen-5]="lang == 'en'"
              >
                <h4
                  class="mt-0 font-weight-semibold mb-0"
                  [innerHTML]="title"
                ></h4>
                <p class="fheader_subtitle" [innerHTML]="subtitle">
                  Field marked with (*) is mandatory
                </p>
              </div>
              <div
                class="col-md-3 col-sm-3 text-center"
                [class.btn_container_shead_en]="lang == 'en'"
              >
                <img
                  [src]="settingsData?.site_logo_dark"
                  style="max-width: 200px"
                />
              </div>
            </div>

            <div
              *ngIf="lang == 'ar'"
              class="col-md-8 p-md-4 p-0 d-flex flex-md-row flex-column-reverse justify-content-between align-items-md-center align-items-end w-100"
            >
              <div
                class="col-md-3 col-sm-3 text-center"
                [class.btn_container_shead_ar]="lang == 'ar'"
              >
                <img
                  [src]="settingsData?.site_logo_dark"
                  style="max-width: 200px"
                />
              </div>
              <div
                class="col-md-8 col-sm-8 text-left"
                [class.plar-5]="lang == 'ar'"
              >
                <h4
                  class="mt-0 font-weight-semibold mb-0"
                  [innerHTML]="title"
                ></h4>
                <p class="fheader_subtitle" [innerHTML]="subtitle">
                  Field marked with (*) is mandatory
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--skeleton-->
  <div class="py-0 mt-100" *ngIf="title == ''">
    <div class="container titlebar-container">
      <div class="row titlebar-container">
        <div class="col-md-12 bg-white">
          <div class="row">
            <div
              class="col-md-12 pb-4 text-left pl-5"
              [class.plen-5]="lang == 'en'"
              [class.plar-5]="lang == 'ar'"
            >
              <breadcrumb #breadParent>
                <ol class="breadcrumb reset-ul inline-nav comma-sep-li">
                  <li class="breadcrumb-item">
                    <a href="" routerLink="/" *ngIf="loadGlabel == false"
                      ><ngx-skeleton-loader
                        [theme]="{  width: '60px', height: '15px', 'margin-bottom': '-3px' }"
                      ></ngx-skeleton-loader
                    ></a>
                  </li>
                  <li class="breadcrumb-item">
                    <a
                      href=""
                      routerLink="/our-services"
                      *ngIf="loadGlabel == false"
                      ><ngx-skeleton-loader
                        [theme]="{  width: '60px', height: '15px', 'margin-bottom': '-3px' }"
                      ></ngx-skeleton-loader
                    ></a>
                  </li>

                  <li class="breadcrumb-item active">
                    <span
                      ><ngx-skeleton-loader
                        [theme]="{  width: '90px', height: '15px', 'margin-bottom': '-3px' }"
                      ></ngx-skeleton-loader
                    ></span>
                  </li>
                </ol>
              </breadcrumb>
            </div>

            <div
              class="col-md-8 text-left pl-5"
              [class.plen-5]="lang == 'en'"
              [class.plar-5]="lang == 'ar'"
            >
              <h4 class="mt-0 font-weight-semibold mb-0">
                <ngx-skeleton-loader
                  [theme]="{  width: '320px', height: '35px', 'margin-bottom': '-3px' }"
                ></ngx-skeleton-loader>
              </h4>
              <p class="fheader_subtitle">
                <ngx-skeleton-loader
                  [theme]="{  width: '300px', height: '15px', 'margin-bottom': '-3px' }"
                ></ngx-skeleton-loader>
              </p>
            </div>
            <div class="col-md-3 text-center">
              <img
                src="../../../assets/img/logo/logo.png"
                style="max-width: 200px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
