import { Component, Input } from '@angular/core';

@Component({
  selector: 'home-about',
  templateUrl: './raksme.homeAboutView.html',
  styleUrls: ['./raksme.homeAboutStyle.css']
})
export class HomeAboutComponent {
  title = 'rak-sme';
  public pageData: object;
  

  @Input("pageContent")
  set setPagesData(_pageData: object){
    this.pageData = _pageData;
  }
}
