import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap, } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { serviceData, clientCategory, sidebarData, tabsData } from 'src/raksme/models/raksme-service/rakservices';

@Injectable({
  providedIn: 'root'
})
export class RakSmeServService {

  private base_url = environment.cms_datas.base_url; 
  private _url: string = "api/";
  api_url: string;
  public fetchStatus: boolean = false;

  constructor(private http:HttpClient) { }


  //get client categories
  getClientCategories(param: string): Observable<clientCategory[]>{
    this.api_url = this.base_url + this._url + param;
    return this.http.get<clientCategory[]>(this.api_url)
                  .pipe(
                    switchMap(data => {
                      return of(data);
                    }),
                    catchError(this.errorHandler))
  }

  //getpages
  getServices(param: string, bodyData: object): Observable<serviceData[]>{
    this.api_url = this.base_url + this._url + param;

    return this.http.post<serviceData[]>(this.api_url, bodyData)
                      .pipe(
                        switchMap(
                          data => {
                            return of(data);
                            this.fetchStatus = true;
                          }
                        ),
                        catchError(this.errorHandler))
  }


  //get sidebar data
  getSidebarData(param: string, bodyData: object): Observable<sidebarData[]>{
    this.api_url = this.base_url + this._url + param;

    return this.http.post<sidebarData[]>(this.api_url, bodyData)
                      .pipe(
                        switchMap(
                          data => {
                            return of(data);
                          }
                        ),
                        catchError(this.errorHandler))
  }

  getServicesTabs(param: string, bodyData: object): Observable<tabsData[]>{
    this.api_url = this.base_url + this._url + param;

    return this.http.post<tabsData[]>(this.api_url, bodyData)
                      .pipe(
                        switchMap(
                          data => {
                            return of(data);
                          }
                        ),
                        catchError(this.errorHandler))
  }




  errorHandler(error: HttpErrorResponse){
    return throwError(error.message || "Server error");
  }

  

}
