import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/raksme/services/events/events.service';
import { Router } from '@angular/router';

@Component({
  selector: 'home-events',
  templateUrl: './raksme.homeEventsView.html',
  styleUrls: ['./raksme.homeEventsStyle.css']
})
export class HomeEventsComponent implements OnDestroy, OnInit {
  title = 'rak-sme';
  public pageData: object;

  @Input("pageContent")
  set setPagesData(_pageData: object){
    this.pageData = _pageData;
  }



  public subscriptions: Subscription[] = [];

  public eventsData: any = [];

  public allItems: any[] = [];

  public lang: string = 'en';
  public langUrl: string = '';

  public timer: any = '';
  public Eventcounter = {
    completed: 1,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    message: ''
  };

  constructor(private eventService: EventsService, private router: Router){}

  ngOnInit(){

    if(this.router.url.includes('/ar') == true){
      this.lang = 'ar';
      this.langUrl = '/ar';
    }

    let bodyData: object = {
      "category" : 0
    }

    this.subscriptions.push(
      this.eventService.getAllEvents('events/'+this.lang, bodyData).subscribe(
        res => {
          this.eventsData = res;

          

          let i = 1;
          for(var x in this.eventsData.data){
            this.allItems.push(this.eventsData.data[x]);
            const counterDate = this.eventsData.data[x].date;

            //counter
            this.CountDownTimer(counterDate);
            this.timer = setInterval(() => {
              this.CountDownTimer(counterDate);
            }, 1000);
          
            if(i == 3){
              break;
            }
            i++
          }
        }
      )
    );
  }


  CountDownTimer(dt)
    {
        let end:any = new Date(dt);
       

        var _second = 1000;
        var _minute = _second * 60;
        var _hour = _minute * 60;
        var _day = _hour * 24;
         this.timer;

        const now:any = new Date();
      var distance = end - now;
      if (distance < 0) {

          clearInterval(this.timer);

          
          
          this.Eventcounter = {
            completed: 3,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            message: 'completed'
          };

          
          

          
      }
      var daysR = Math.floor(distance / _day);
      var hoursR = Math.floor((distance % _day) / _hour);
      var minutesR = Math.floor((distance % _hour) / _minute);
      var secondsR = Math.floor((distance % _minute) / _second);

      
      

      this.Eventcounter = {
        completed: 1,
        days: daysR,
        hours: hoursR,
        minutes: minutesR,
        seconds: secondsR,
        message: 'to go'
      }
        
      // console.log(this.Eventcounter);
         
    }

  ngOnDestroy(){
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
