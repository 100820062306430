import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { RakSmeServService } from 'src/raksme/services/raksme-services/rakservices.service';
import { Router } from '@angular/router';

@Component({
  selector: 'home-services',
  templateUrl: './raksme.homeServicesView.html',
  styleUrls: ['./raksme.homeServicesStyle.css']
})
export class HomeServicesComponent implements OnInit, OnDestroy {
  title = 'rak-sme';

  public pageData: object;
  public subscriptions: Subscription[] = [];
  public serviceDatas: any = [];
  public lang: string = '';
  public langUrl: string = '';

  @Input("pageContent")
  set setPagesData(_pageData: object){
    this.pageData = _pageData;
  }

  constructor(private rakService: RakSmeServService, private router: Router){}

  ngOnInit(): void {

    if(this.router.url.includes('/ar')){
      this.lang = 'ar';
      this.langUrl = '/ar';
    }else{
      this.lang = 'en';
    }

    const bodyData: object = {
      "url": 'all'
    }
    this.subscriptions.push(
      this.rakService.getServices('services/'+this.lang, bodyData).subscribe(
        res => {
          this.serviceDatas = res;
        },
        error => {
          console.log(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
