<div class="container">
  <div class="row d-flex flex-wrap">
    <ng-container *ngIf="showHideF == true">
      <div
        class="lqd-column col-md-3 col-sm-6"
        *ngIf="mainFooter4.data?.length > 0 && mainFooter4.status == 1 else footerSkeleton4"
      >
        <h3
          class="widget-title mb-30 text-white font-size-15 text-uppercase ltr-sp-2"
          [innerHTML]="mainFooter4_title"
        ></h3>

        <div
          class="contact-form contact-form-inputs-filled contact-form-button-block contact-form-button-circle mb-60"
        >
          <form
            [formGroup]="footerForm"
            novalidate="novalidate"
            (ngSubmit)="SubmitData()"
          >
            <div class="row">
              <div class="col-md-12">
                <input
                  class="bg-white"
                  [class.invalid_input_form]="Message.invalid && Message.touched"
                  rows="2"
                  name="message"
                  formControlName="message"
                  placeholder="{{message_field_placeholder}}"
                />
                <div
                  *ngIf="Message.invalid && Message.touched"
                  style="margin-top: -19px"
                >
                  <small class="text-danger" *ngIf="Message.errors?.required"
                    >{{message_field_success}}</small
                  >
                  <small class="text-danger" *ngIf="Message.errors?.pattern"
                    >{{message_field_error}}</small
                  >
                </div>
              </div>

              <div class="col-md-12">
                <input
                  class="bg-white"
                  type="email"
                  [class.invalid_input_form]="Email.invalid && Email.touched"
                  name="email"
                  placeholder="{{email_field_placeholder}}"
                  formControlName="email"
                />
                <div
                  *ngIf="Email.invalid && Email.touched"
                  style="margin-top: -19px"
                >
                  <small class="text-danger" *ngIf="Email.errors?.required"
                    >{{email_field_success}}</small
                  >
                  <small
                    class="text-danger"
                    *ngIf="Email.errors?.email || Email.errors?.pattern"
                    >{{email_field_error}}</small
                  >
                </div>
              </div>

              <div class="col-md-12 text-md-right">
                <input
                  class="font-size-13 ltr-sp-1 font-weight-bold"
                  type="submit"
                  value="{{submit_field_label}}"
                />
              </div>
            </div>
          </form>
          <br />
          <alert
            *ngFor="let alert of alerts"
            [type]="alert.type"
            dismissOnTimeout="5000"
          >
            {{ alert.text }}
          </alert>
        </div>
      </div>
    </ng-container>

    <div
      class="lqd-column col-md-3 col-sm-6"
      *ngIf="mainFooter1.data?.length > 0 && mainFooter1.status == 1 else footerSkeleton"
    >
      <figure class="mb-30">
        <img [src]="mainFooter1_logo" alt="" style="max-width: 175px" />
      </figure>
      <p [innerHTML]="mainFooter1_text"></p>
      <img [src]="mainFooter1_qr_code" style="max-width: 150px" />
      <p
        style="
          color: white;
          padding: 10px 0px;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 2px;
          font-weight: 800;
        "
        [innerHTML]="mainFooter1_qr_code_text"
      ></p>
    </div>

    <div
      class="lqd-column col-md-3 col-sm-6"
      *ngIf="mainFooter2.data?.length > 0 && mainFooter2.status == 1 else footerSkeleton2"
    >
      <h3
        class="widget-title mb-30 text-white font-size-15 text-uppercase ltr-sp-2"
        [innerHTML]="mainFooter2_title"
      ></h3>
      <ul
        class="lqd-custom-menu reset-ul font-size-14 lh-2"
        *ngIf="mainFooterMenu.data?.length > 0 && mainFooterMenu.status == 1"
      >
        <li *ngFor="let footerMenu of mainFooterMenu.data" class="mb-1">
          <a
            *ngIf="footerMenu.pages_id != 0 && footerMenu.new_windows == 0"
            routerLink="/{{footerMenu.link}}{{langUrl}}"
            >{{footerMenu.name}}</a
          >

          <a
            *ngIf="footerMenu.pages_id == 0 && footerMenu.new_windows == 0"
            href="{{footerMenu.link}}{{langUrl}}"
            >{{footerMenu.name}}</a
          >

          <a
            *ngIf="footerMenu.pages_id != 0 && footerMenu.new_windows == 1"
            routerLink="/{{footerMenu.link}}{{langUrl}}"
            >{{footerMenu.name}}</a
          >
          <a
            *ngIf="footerMenu.pages_id == 0 && footerMenu.new_windows == 1"
            href="{{footerMenu.link}}{{langUrl}}"
            >{{footerMenu.name}}</a
          >
        </li>
      </ul>
    </div>

    <div
      class="lqd-column col-md-3 col-sm-6"
      *ngIf="mainFooter3.data?.length > 0 && mainFooter3.status == 1 else footerSkeleton3"
    >
      <h3
        class="widget-title mb-30 text-white font-size-15 text-uppercase ltr-sp-2"
        [innerHTML]="mainFooter3_title"
      ></h3>
      <p [innerHTML]="mainFooter3_address"></p>
      <ul
        class="social-icon social-icon-lg"
        *ngIf="footerSocials.data?.length > 0 && footerSocials.status == 1"
      >
        <li *ngFor="let social of footerSocials.data">
          <a href="{{social.url}}" target="_blank" title="{{social.name}}"
            ><i class="fa fa-{{social.name}}"></i
          ></a>
        </li>
      </ul>
    </div>

    <ng-container *ngIf="showHideF == false">
      <div
        class="lqd-column col-md-3 col-sm-6"
        *ngIf="mainFooter4.data?.length > 0 && mainFooter4.status == 1 else footerSkeleton4"
      >
        <h3
          class="widget-title mb-30 text-white font-size-15 text-uppercase ltr-sp-2"
          [innerHTML]="mainFooter4_title"
        ></h3>

        <div
          class="contact-form contact-form-inputs-filled contact-form-button-block contact-form-button-circle mb-60"
        >
          <form
            [formGroup]="footerForm"
            novalidate="novalidate"
            (ngSubmit)="SubmitData()"
          >
            <div class="row">
              <div class="col-md-12">
                <input
                  class="bg-white"
                  [class.invalid_input_form]="Message.invalid && Message.touched"
                  rows="2"
                  name="message"
                  formControlName="message"
                  placeholder="{{message_field_placeholder}}"
                />
                <div
                  *ngIf="Message.invalid && Message.touched"
                  style="margin-top: -19px"
                >
                  <small class="text-danger" *ngIf="Message.errors?.required"
                    >{{message_field_success}}</small
                  >
                  <small class="text-danger" *ngIf="Message.errors?.pattern"
                    >{{message_field_error}}</small
                  >
                </div>
              </div>

              <div class="col-md-12">
                <input
                  class="bg-white"
                  type="email"
                  [class.invalid_input_form]="Email.invalid && Email.touched"
                  name="email"
                  placeholder="{{email_field_placeholder}}"
                  formControlName="email"
                />
                <div
                  *ngIf="Email.invalid && Email.touched"
                  style="margin-top: -19px"
                >
                  <small class="text-danger" *ngIf="Email.errors?.required"
                    >{{email_field_success}}</small
                  >
                  <small
                    class="text-danger"
                    *ngIf="Email.errors?.email || Email.errors?.pattern"
                    >{{email_field_error}}</small
                  >
                </div>
              </div>

              <div class="col-md-12 text-md-right">
                <input
                  class="font-size-13 ltr-sp-1 font-weight-bold"
                  type="submit"
                  value="{{submit_field_label}}"
                />
              </div>
            </div>
          </form>
          <br />
          <alert
            *ngFor="let alert of alerts"
            [type]="alert.type"
            dismissOnTimeout="5000"
          >
            {{ alert.text }}
          </alert>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ngx-spinner bdColor="rgba(0,0,0,0.4)" color="rgba(0,0,0,0.6)" type="none">
  <img
    src="../../../../assets/img/Loadingicon.gif"
    style="width: 280px; opacity: 0.6"
  />
</ngx-spinner>

<ng-template #footerSkeleton>
  <div class="item-with-background lqd-column col-md-3 col-sm-6">
    <figure class="mb-30">
      <ngx-skeleton-loader
        count="1"
        [animation]="animation"
        [theme]="{
            'border-radius': '1px',
            'background-color': '#808291',
            border: '1px solid #808291',
            'animation-duration': '2s',
            width: '120px',
            height: '70px'
          }"
      >
      </ngx-skeleton-loader>
    </figure>
    <ngx-skeleton-loader
      count="3"
      [animation]="animation"
      [theme]="{
            'border-radius': '1px',
            'height': '10px',
            'background-color': '#808291',
            border: '1px solid #808291',
            'animation-duration': '2s'
          }"
    >
    </ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      [animation]="animation"
      [theme]="{
            'border-radius': '1px',
            'background-color': '#808291',
            border: '1px solid #808291',
            'animation-duration': '2s',
            width: '100px',
            height: '100px'
          }"
    >
    </ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      [animation]="animation"
      [theme]="{
            'border-radius': '1px',
            'height': '15px',
            'background-color': '#808291',
            border: '1px solid #808291',
            'animation-duration': '2s'
          }"
    >
    </ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #footerSkeleton2>
  <div class="item-with-background lqd-column col-md-3 col-sm-6">
    <ngx-skeleton-loader
      count="1"
      [animation]="animation"
      [theme]="{
            'border-radius': '1px',
            'background-color': '#808291',
            border: '1px solid #808291',
            'animation-duration': '2s'
          }"
    >
    </ngx-skeleton-loader>
    <ul class="lqd-custom-menu reset-ul font-size-14 lh-2">
      <li class="mb-1">
        <ngx-skeleton-loader
          count="3"
          [animation]="animation"
          [theme]="{
            'border-radius': '1px',
            'height': '10px',
            'background-color': '#808291',
            border: '1px solid #808291',
            'animation-duration': '2s'
          }"
        >
        </ngx-skeleton-loader>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #footerSkeleton3>
  <div class="item-with-background lqd-column col-md-3 col-sm-6">
    <ngx-skeleton-loader
      count="1"
      [animation]="animation"
      [theme]="{
            'border-radius': '1px',
            'background-color': '#808291',
            border: '1px solid #808291',
            'animation-duration': '2s'
          }"
    >
    </ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="4"
      [animation]="animation"
      [theme]="{
          'border-radius': '1px',
          'height': '10px',
          'background-color': '#808291',
          border: '1px solid #808291',
          'animation-duration': '2s'
        }"
    >
    </ngx-skeleton-loader>
    <ul class="social-icon social-icon-lg">
      <ngx-skeleton-loader
        count="3"
        [animation]="animation"
        appearance="circle"
        [theme]="{
          'border-radius': '5px',
          'background-color': '#808291',
          border: '1px solid #808291',
          'animation-duration': '2s',
          width: '20px',
          height: '20px'
        }"
      >
      </ngx-skeleton-loader>
    </ul>
  </div>
</ng-template>

<ng-template #footerSkeleton4>
  <div class="item-with-background lqd-column col-md-3 col-sm-6">
    <ngx-skeleton-loader
      count="1"
      [animation]="animation"
      [theme]="{
            'border-radius': '1px',
            'background-color': '#808291',
            border: '1px solid #808291',
            'animation-duration': '2s'
          }"
    >
    </ngx-skeleton-loader>

    <div
      class="contact-form contact-form-inputs-filled contact-form-button-block contact-form-button-circle mb-60"
    >
      <ngx-skeleton-loader
        count="3"
        [animation]="animation"
        [theme]="{
            'border-radius': '1px',
            height: '25px',
            'background-color': '#808291',
            border: '1px solid #808291',
            'animation-duration': '2s'
          }"
      >
      </ngx-skeleton-loader>

      <div class="contact-form-result hidden"></div>
    </div>
  </div>
</ng-template>
