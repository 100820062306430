import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeneralLabelService } from 'src/raksme/services/general-label/label.service';
import { error } from 'protractor';
import { Router } from '@angular/router';
import { WidgetsService } from 'src/raksme/services/widgets/widgets.service';

@Component({
  selector: 'raksme-header',
  templateUrl: './raksme.headerView.html',
  styleUrls: ['./raksme.headerStyle.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  title = 'rak-sme';
  public generalLabels: any = [];
  public subscription: Subscription[] = [];
  public lang: string;
  public settingsData: any = [];
  constructor(private labelService: GeneralLabelService, private router: Router, private widgetService: WidgetsService){}
  ngOnInit(): void{
    const url = this.router.url;
      
    
    if (this.router.url.includes('/ar') == true){
      this.lang = 'ar';
      localStorage.setItem('RAKSME_LANG', 'ar_AE');
      // const language = localStorage.getItem('RAKSME_LANG');
      // if(language){
      //   let item = JSON.parse(language);
      //   const now = new Date();
      //   const data = {
      //     value: item.value,
      //     // expiry: now.getTime() + 1800000
      //   }
      //   localStorage.setItem('RAKSME_LANG', JSON.stringify(data));
      // }else{
      //   const now = new Date();
      //   const item = {
      //     value: 'ar_AE',
      //     // expiry: now.getTime() + 1800000
      //   }
      //   localStorage.setItem('RAKSME_LANG', JSON.stringify(item));
      // }

      
      
    }else{
      this.lang = 'en';

      localStorage.setItem('RAKSME_LANG', 'en_US');

      
    }

    this.subscription.push(
      this.labelService.getLabels('general-label/'+this.lang).subscribe(
        res => {
          this.generalLabels = res;
        },
        error => {
          console.log(error);
        }
      )
    );
    
    this.subscription.push(
      this.widgetService.getSettings('website-settings/'+this.lang).subscribe(
        res => {
          this.settingsData = res;
          
        },
        error => console.log(error)
      )
    )

  }

  ngOnDestroy():void {
    this.subscription.forEach(sub => {
      sub.unsubscribe();
    })
  }
  
}
