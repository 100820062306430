<div class="titlebar titlebar-md scheme-light text-center bg-center" [ngStyle]="bgImage">
    <!-- Header menu -->
    <raksme-header></raksme-header>
    <!-- end header menu -->
    <div class="titlebar-inner">
       <div class="container titlebar-container">
          <div class="row titlebar-container">
             <div class="titlebar-col col-md-12">
                <h1 class="m-0" *ngIf="title != undefined || title != ''">{{ title }}</h1>
                <h1 class="m-0" *ngIf="title == undefined || title == ''"><ngx-skeleton-loader [theme]="{  width: '300px', height: '45px', 'margin-bottom': '0px', 'background-color': '#808291' }" ></ngx-skeleton-loader></h1>
                
                <breadcrumb #breadParent>
                  <ol class="breadcrumb reset-ul inline-nav comma-sep-li mt-1 mb-4">
                     <li class="breadcrumb-item">
                        <a href="" routerLink="/" *ngIf="loadGeneLabel == true">{{generalLabels.data?.home_label}}</a>
                        <a href="" routerLink="/" *ngIf="loadGeneLabel == false"><ngx-skeleton-loader [theme]="{  width: '60px', height: '15px', 'margin-bottom': '-3px', 'background-color': '#808291' }" ></ngx-skeleton-loader></a>
                     </li>
                     <ng-template ngFor let-route [ngForOf]="breadParent.breadcrumbs">
                        
                        <!-- <li *ngIf="!route.terminal" class="breadcrumb-item">
                           <a href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
                        </li> -->
                        <li *ngIf="route.terminal" class="breadcrumb-item active text-white">
                           <span *ngIf="title != undefined || title != ''">{{ title }}</span>
                           <span *ngIf="title == undefined || title == ''"><ngx-skeleton-loader [theme]="{  width: '60px', height: '15px', 'margin-bottom': '-3px', 'background-color': '#808291' }" ></ngx-skeleton-loader></span>
                        </li>
                     </ng-template>
                  </ol>
               </breadcrumb>
                <a class="titlebar-scroll-link" (click)="scrollToContent()" data-localscroll="true" data-fittext="true" data-fittext-options='{ "maxFontSize": 50, "minFontSize": 50 }'><i class="fa fa-angle-down"></i></a>
             </div>
          </div>
       </div>
       <div class="col-md-12" style="position: absolute;z-index: -1;bottom: 0;">
          <!-- <img src="../../../assets/img/pattern-1.png" style="max-width: 400px;opacity: 0.2;float: right;"> -->
       </div>
    </div>
    <div class="header-overlay-raksme"></div>
 </div>