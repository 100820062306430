import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeneralLabelService } from 'src/raksme/services/general-label/label.service';
import { Router } from '@angular/router';

@Component({
    selector: 'raksme-pheaders',
    templateUrl: './raksme.pheaderView.html',
    styleUrls: ['./raksme.pheaderStyle.css']
})
export class PheaderComponent implements OnInit, OnDestroy {
  
  bgImage: any;
  title: string = '';
  public subscriptions: Subscription[] = [];
  public generalLabels: any = [];
  public lang: string = 'en';
  public loadGeneLabel: boolean = false;

  @Input("header-data")
  set setHeaderData(_headerData: object){
    
    this.bgImage = {
      backgroundImage: 'url('+_headerData['bgimage']+')'
    };

    this.title = _headerData['title'];

  }

  constructor(private labelService: GeneralLabelService, private router: Router){}

  ngOnInit():void{

    if (this.router.url.includes('/ar') == true){
      this.lang = 'ar';
    }else{
      this.lang = 'en';
    }

    this.subscriptions.push(
      this.labelService.getLabels('general-label/'+this.lang).subscribe(
        res => {
          this.generalLabels = res;
          this.loadGeneLabel = true;
        },
        error => {
          console.log(error);
        }
      )
    );
  }

  scrollToContent(): void{
    const element = document.querySelector("#content");
        if (element){
          element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
  }

  ngOnDestroy():void{
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  
}
