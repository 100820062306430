import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap, } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

//models
import { HeaderMenu, FooterMenu } from 'src/raksme/models/menu/header';
import { FooterData } from 'src/raksme/models/footer/footer';
import { SettingsData } from 'src/raksme/models/settings/settings';

@Injectable({
  providedIn: 'root'
})
export class WidgetsService {

  private base_url = environment.cms_datas.base_url; 
  private _url: string = "api/";
  api_url: string;
  

  constructor(public http:HttpClient) { }

   getHeaderMenu(param: string): Observable<HeaderMenu[]>{
    this.api_url = this.base_url + this._url + param;
    return this.http.get<HeaderMenu[]>(this.api_url)
                  .pipe(
                    switchMap(data => {
                      return of(data);
                    }),
                    catchError(this.errorHandler))
  }


  //getting main main footer menu
  getFooterMenu(param: string): Observable<FooterMenu[]>{
    this.api_url = this.base_url + this._url + param;

    return this.http.get<FooterMenu[]>(this.api_url).pipe(
      switchMap(data => {
        return of(data);
      }), catchError(this.errorHandler)
    )
  }


  //getting footer
  getFooter(param: string, bodyData: object): Observable<FooterData[]>{
    this.api_url = this.base_url + this._url + param;

    return this.http.post<FooterData[]>(this.api_url, bodyData)
                      .pipe(
                        switchMap(
                          data => {
                            return of(data)
                          }
                        ),
                        catchError(this.errorHandler))
  }
  
  
   


  //getSocials
  getFooterSocials(param: string): Observable<FooterMenu[]>{
    this.api_url = this.base_url + this._url + param;

    return this.http.get<FooterMenu[]>(this.api_url).pipe(
      switchMap(data => {
        return of(data);
      }), catchError(this.errorHandler)
    )
  }


  getSettings(param: string): Observable<SettingsData[]>{
    this.api_url = this.base_url + this._url + param;
    return this.http.get<SettingsData[]>(this.api_url)
                  .pipe(
                    switchMap(data => {
                      return of(data);
                    }),
                    catchError(this.errorHandler))
  }

  getWSettings(param: string, bodyData: object): Observable<SettingsData[]>{
    this.api_url = this.base_url + this._url + param;
    return this.http.post<SettingsData[]>(this.api_url, bodyData)
                  .pipe(
                    switchMap(data => {
                      return of(data);
                    }),
                    catchError(this.errorHandler))
  }

  errorHandler(error: HttpErrorResponse){
    return throwError(error.message || "Server error");
  }

  

}




