interface Scripts {
    name: string;
    src: string;
}  
export const ScriptStore: Scripts[] = [
    {name: 'modernizer', src: '../../../assets/vendors/modernizr.min.js'},
    {name: 'jquery', src: '../../../assets/vendors/jquery.min.js'},
    {name: 'themeVendor', src: '../../../assets/js/theme-vendors.js'},
    {name: 'pagepiling', src: '../../../assets/vendors/pagePiling/dist/jquery.pagepiling.min.js'},
    {name: 'theme', src: '../../../assets/js/theme.min.js'},
    {name: 'custom', src: '../../../assets/js/custom.js'}
];